import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Paper,
  Grid,
  Popper,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LoadingModal from "../../helpers/LoadingModal";
import { GetApplicationData, ConfirmDetailStep, apiCallWithAxiosCancel } from "../../helpers/api";
import { toast } from "react-toastify";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from "react-router-dom";

function ConfirmDetails({ onNext, onBack, onSaveAndExit, setMaxStepReached }) {
  const applicationId = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await GetApplicationData(applicationId, dispatch);
        setMaxStepReached(response?.data[0]?.step_no + 1)
        setData(response?.data || []);
      } catch (error) {
        toast.error("Error fetching user data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [applicationId]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        app_id: applicationId,
        is_confirmed: true,
      };
      await apiCallWithAxiosCancel((cancelToken) =>
        ConfirmDetailStep(payload, dispatch, cancelToken)
      );      // navigate("/");
    } catch (error) {
        if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
          console.error("Error logging in:", error);  
        }
    } finally {
      setLoading(false);
    }
  }
  const handleSaveandExitSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        app_id: applicationId,
        is_confirmed: true,
      };
      await ConfirmDetailStep(payload, dispatch);
       navigate("/");
    } catch (error) {
      if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
        console.error("Error logging in:", error);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <LoadingModal open={loading} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ minHeight: "100%" }}
        flexDirection={"column"}
      >
        <Box sx={{ px: 5 }} marginTop={2} cl>
          <p className="font-bold text-2xl text-[#1E1D1B]">Confirm Details</p>
          <div style={{ maxHeight: `calc(${screenHeight}px - 90px - 32px - 32px - 20px - 60px - 60px )`, overflowY:"auto"}} className="scrollbar overflow-y-auto pr-3 mt-8">
            <p className="font-bold text-xl text-[#1E1D1B]">Jurisdiction:</p>
            <p className="font-normal text-sm text-[#1E1D1B] mt-1">{data[0]?.jurisdiction[0]?.nameEn}</p>
            <p className="font-bold text-xl mt-5 text-[#1E1D1B]">Your Business Activities:</p>
            <div className="flex flex-wrap gap-1 mt-2">
              {data[0]?.sub_activities?.map((activity) => (
                <Tooltip key={activity.id} title={activity.activityNameEn} arrow>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "350px",
                      position: "relative",
                    }}
                    className={`cursor-pointer justify-center mt-2 mr-3 px-5 py-1.5 text-[#1E1D1B] bg-[#EBEBE6]  rounded-[30px] text-[0.8em]`}
                  >{activity?.activityNameEn}</div>
                  {/* <Chip sx={{ backgroundColor: "#EBEBE6" }} key={activity?.id} label={activity?.activityNameEn} /> */}
                </Tooltip>
              ))}
            </div>
            <p className="font-bold text-xl text-[#1E1D1B] mt-5">Type of License:</p>
            <div className="flex flex-wrap gap-1 mt-2">
              {data?.[0]?.license_data?.map((activity, index) => (

                <p key={activity.id} className="font-normal text-sm text-[#1E1D1B] mt-1">{activity}{ (index+1) < data?.[0]?.license_data.length ? "," : "" }</p>
              ))}
            </div>
            <div className="flex items-center gap-2 mt-5">
              <p className="font-bold text-xl text-[#1E1D1B] ">External Approval Required:</p>
              <IconButton onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} aria-describedby={id} type="button">
                  <InfoOutlinedIcon sx={{ color: "#1E1D1B" }} />
                  </IconButton>
                  </div>
            <Popper placement='right-start' id={id} open={open} anchorEl={anchorEl} onMouseLeave={handleMouseLeave}>
              <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', borderRadius: 3, maxWidth: "500px", color: "#1E1D1B" }}>
                External approval is permission given by a third-party government entity and allows a business activity to take place in the UAE. This does not include refundable security / insurance costs / additional fee etc.
              </Box>
            </Popper>
            <div className="flex gap-1">
              <p className="font-normal text-sm text-[#1E1D1B] mt-1">
                {data[0]?.external_approval ? "Yes" : "No"}
              </p>
            </div>

            <p className="font-bold text-xl text-[#1E1D1B] mt-5">No. of Shareholders:</p>
            <div className="flex flex-wrap gap-1 mt-2">
              <p className="font-normal text-sm text-[#1E1D1B] mt-1">{data[0]?.partner_details.length}</p>
            </div>

            <p className="font-bold text-xl text-[#1E1D1B] mt-5">No. of Visas Required:</p>
            <div className="flex flex-wrap gap-6 mt-2">
              <p className="font-normal text-sm text-[#1E1D1B] mt-1">Manager/Employees Visa: {data[0]?.costing[1]?.visa_for_manager}</p>
              <p className="font-normal text-sm text-[#1E1D1B] mt-1">Investor Visas: {data[0]?.costing[0]?.visa_for_investor}</p>
            </div>
            <p className="font-bold text-xl text-[#1E1D1B] mt-5">Type of Office:</p>
            <div className="flex flex-wrap gap-6 mt-2">
              <p className="font-normal text-sm text-[#1E1D1B] mt-1"> {data[0]?.costing[5]?.workspace_type}</p>
            </div>
          </div>
        </Box>
        <Box
          sx={{ alignItems: "flex-end", display: "flex", gap: "0.1rem", mt: 2, borderTop: "2px solid #EBEBE6" }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none"
              },
              color: "#1E1D1B",
              borderRadius: "0px",
              textTransform: "none",
              maxWidth: "28%",
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none"
              },
              color: "#1E1D1B",
              borderRadius: "0px",
              textTransform: "none",
              maxWidth: "28%",
            }}
            onClick={() => { onSaveAndExit(); handleSaveandExitSubmit() }}
          >
            Save & Exit
          </Button>

          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#81ef6f",
              fontWeight: 600,
              color: "#1E1D1B",
              borderRadius: "0px",
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none"
              },
            }}
            onClick={() => { onNext(); handleSubmit() }} // Open modal on button click
          >
            Continue to Payment
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ConfirmDetails;
