import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Button,
  Typography,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { RegistrationValidationSchema } from './ValidationSchema';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarNew from './NavbarNew';
import { MuiTelInput } from 'mui-tel-input';

const SignUpPage = () => {
  const [response, setRegistrationResponse] = useState(null);
  const [apiloading, setApiloading] = useState(false);
  const [countryCode, setCountryCode] = useState('AE');
  const [showPassword, setShowPassword] = useState(false);
  const [invalidCountryCode, setInvalidCountryCode] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    first_name: '',
    email: '',
    password: '',
    phone_number: '+971',
    //nationality_id: "",
  };

  const validationSchema = RegistrationValidationSchema;

  const handleSubmit = async (values, {setErrors}) => {

    const phoneNumber = values.phone_number;

    try {
      if (!phoneNumber) {
        setErrors({phone_number: "Invalid phone number." })
        setApiloading(false);
        return;
      }
      const country_code = countryCode;
      const phone_number = phoneNumber;
      if (!country_code) {
        setInvalidCountryCode(true);
        return;
      }
      console.log('country code before sending', country_code);

      console.log('phone nuymber', phone_number);
      console.log('Nationality code', country_code);
      setApiloading(true);
      const response = await axios.post(
        'https://g2scsqxnpk.ap-southeast-1.awsapprunner.com/api/auth/register/',
        { ...values, country_code, phone_number },
      );
      console.log('Response:', response.data);
      setRegistrationResponse(response.data);
      toast.success('Thank you for signing up! A verification link has been sent to your email. Please check your inbox to verify your account.');
      console.log('country code after sending', country_code);
      setApiloading(false);
      navigate('/signin');
    } catch (error) {
      console.error('Error:', error);
      setApiloading(false);
      if (error.response.data.error === 'This user is already registered.') {
        setErrors({email: "This user is already registered." })
      }
      else if (error.response.data.error === 'Invalid country code provided.') {
        setErrors({phone_number: "Invalid country code provided." })
      }
      else {
        setErrors({phone_number: "Something went wrong." })

      }
    }
  };

  useEffect(() => {
    console.log(response);
  }, [response]);

  return (
    <>
      <div className="min-h-screen mb-5 bg-[#EBEBE6]">
        <NavbarNew />
        <div className="flex items-center min-h-[80vh]">
          <Container>
            <Grid
              container
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid
                item
                xs={12}
                sm={11}
                md={9}
                lg={6}
              >
                <div className="bg-[#fbfafb] py-9 rounded-[20px] flex flex-col gap-8 items-center">
                  <p className="text-3xl text-[#1E1D1B] font-bold">Sign Up</p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched, setFieldValue, values, setFieldTouched }) => (
                      <Form
                        fullWidth
                        className="space-y-4 flex flex-col items-center"
                      >
                        <div className="flex w-full flex-col items-start">
                          <div className="text-[#323338] font-semibold mb-1">
                            Full Name
                          </div>
                          <Field
                            type="text"
                            fullWidth
                            name="first_name"
                            className={`p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6] ${
                              errors.first_name && touched.first_name
                                ? 'border-red-600'
                                : 'border-[#EBEBE6]'
                            }`}
                            placeholder="Enter full name"
                          />
                          {errors.first_name && touched.first_name ? (
                            <div className="text-red-500 text-sm">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="flex w-full flex-col items-start">
                          <div className="text-[#323338] font-semibold mb-1">
                            Email
                          </div>
                          <Field
                            type="text"
                            fullWidth
                            name="email"
                            className={`p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6] ${
                              errors.email && touched.email
                                ? 'border-red-600'
                                : 'border-[#EBEBE6]'
                            }`}
                            placeholder="Enter an email"
                          />
                          {errors.email && touched.email ? (
                            <div className="text-red-500 text-sm">
                              {errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="flex w-full flex-col items-start">
                          <div className="text-[#323338] font-semibold mb-1">
                            Create a Password
                          </div>
                          <Field
                            name="password"
                            as={TextField}
                            style={{ border: '0px' }}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            sx={{
                              '& .MuiInputBase-root': {
                                maxHeight: '43px', // Setting max height for the input base
                              },
                              '& .MuiOutlinedInput-input': {
                                padding: '10px', // Adjust padding as needed
                              },
                            }}
                            variant="outlined"
                            className={`p-2 w-full bg-transparent rounded-md border-2 border-[#EBEBE6] ${
                              errors.password && touched.password
                                ? 'border-red-600'
                                : 'border-[#EBEBE6]'
                            }`}
                            placeholder="At least 8 character"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors.password && touched.password ? (
                            <div className="text-red-500 w-80 text-sm">
                              {errors.password}
                            </div>
                          ) : null}
                          <div
                            className="text-[#8C8C8C] text-[14px] mt-1 "
                            style={{ lineHeight: '1' }}
                          >
                            Create a secure password using a combination
                            <br />
                            of letters, numbers, and symbols.
                          </div>
                        </div>

                        <div className="flex w-full flex-col items-start">
                          <div className="text-[#323338] font-semibold mb-1">
                            Enter Your Phone No:
                          </div>
                          <MuiTelInput
                            name="phone_number"
                            sx={{
                              border: '0px', // Optional: Setting border to 0px
                              '& .MuiInputBase-root': {
                                maxHeight: '43px', // Setting max height for the input base
                              },
                              '& .MuiOutlinedInput-input': {
                                padding: '10px', // Adjust padding as needed
                              },
                            }}
                            disableFormatting 
                            forceCallingCode
                            onBlur={() => setFieldTouched('phone_number', true)} // Manually trigger Formik's blur
                            defaultCountry={'us'}
                            value={values.phone_number}
                            onChange={(value, allDetails) => {
                              setFieldValue('phone_number', value);
                              setCountryCode(allDetails.countryCode);
                              console.log('the all details', allDetails);
                            }}
                            fullWidth
                          />
                          {errors.phone_number && touched.phone_number ? (
                            <div className="text-red-500 w-80 text-sm">
                              {errors.phone_number}
                            </div>
                          ) : null}
                          {invalidCountryCode && (
                            <div className="text-red-500 w-80 text-sm">
                            Invalid Phone Number
                          </div>
                          )}
                        </div>

                        <Button
                          type="submit"
                          variant="contained"
                          disableElevation
                          fullWidth
                          disabled={apiloading}
                          sx={{
                            textTransform: 'none',
                            backgroundColor: '#81EF6F',
                            borderRadius: '30px',
                            fontSize: '18px',
                            color: '#000000',
                            '&:hover': {
                              backgroundColor: '#81EF6F',
                            },
                          }}
                          endIcon={
                            apiloading && (
                              <svg
                                className="animate-spin w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                              </svg>
                            )
                          }
                        >
                          Sign Up
                        </Button>

                        <Typography
                          variant="body2"
                          align="center"
                          style={{ marginTop: '1rem', color: '#1E1D1B' }}
                        >
                          Don’t have an account?
                          <Link
                            className="ml-1 underline text-sm"
                            to="/signin"
                            variant="body2"
                          >
                            Sign In
                          </Link>
                        </Typography>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
