import { Box, Container, Stack } from "@mui/material";
import SideBar from "./Sidebar";
import NavBar from "./Navbar";

const Dashboard = ({ children }) => {
    return (
        <>
            <Stack direction={"row"} className="w-full" >
                <Box flex={8} sx={{ display: "flex", flexDirection: "column", width:"100%" }}>
                    <Box sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
                        <NavBar />
                    </Box>
                    <div
                        style={{maxHeight:"calc(100vh - 120px)", minHeight:"calc(100vh - 120px)"}}
                        className="scrollbar overflow-y-auto min-h-screen lg:min-h-fit flex-1 px-10"
                    >
                        {children}
                    </div>
                </Box>
            </Stack>
        </>
    );
};

export default Dashboard;
