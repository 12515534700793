import axios from "axios";
import { getToken, getRefresh } from "../helpers/getToken";
import { logout } from "../features/appSlice";


// export const Logout = async (dispatch, user) => {
//     try {

//         const response = await axios.post("https://g2scsqxnpk.ap-southeast-1.awsapprunner.com/api/auth/logout/", {
//             refresh_token: getRefresh()
//         }, {
//             headers: {
//                 'Authorization': `Bearer ${getToken()}`
//             }
//         });

//         localStorage.clear();
//         dispatch(logout());
//     } catch (error) {
//         console.log("error is here",error);
//         if (error) {
//             localStorage.clear();
//             dispatch(logout());
//         }
//         throw error;
//     }
// }

// Modified Logout function with CancelToken
export const Logout = async (dispatch, user, cancelToken) => {
    try {
        const response = await axios.post(
            "https://g2scsqxnpk.ap-southeast-1.awsapprunner.com/api/auth/logout/",
            {
                refresh_token: getRefresh(),
            },
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                },
                cancelToken,  // Pass the cancel token to allow for cancellation
            }
        );

        localStorage.clear();
        dispatch(logout());

        return response; // Return the entire response object
    } catch (error) {
        console.log("error is here", error);
        if (error) {
            localStorage.clear();
            dispatch(logout());
        }
        throw error; // Rethrow error for further handling
    }
};  