import React from 'react';
import { Box, Button } from '@mui/material';
import logo from "../assets/svgs/firmfox-logo.svg"
import { useNavigate } from 'react-router-dom';
function NavbarNew({ children }) {

    const navigate = useNavigate();
    const isSignUpPage = window.location.href.includes("/signup");
    const isLoginPage = window.location.href.includes("/signin");
    const handleRedirect = () => {
        window.location.href = 'https://firmfox.com/our-services';
      };
    return (
        <div className='mb-3 md:px-16'>
            <div className='flex pt-6 md:pt-0 md:flex-row flex-col justify-between items-center gap-0 min-h-[100px]'
            >
                <div>
                    <img src={logo} alt="logo of the firmfox" />
                </div>
                <div className='flex pt-6 md:pt-0 justify-end items-center gap-0'>
                    <div className='w-32 flex justify-center'> <Button onClick={() => navigate("/signin")} variant="text" sx={{ color: "#1E1D1B", textTransform: "capitalize", fontSize: "17px",textDecoration: isLoginPage ? "underline" : "none" }} >Sign In</Button></div>
                    <div className='w-28 '><Button onClick={() => navigate("/signup")} variant="text" sx={{textDecoration: isSignUpPage ? "underline" : "none", color: "#1E1D1B", textTransform: "capitalize", fontSize: "17px" }} >Sign Up</Button> </div>
                    <div className='w-[150px] '>
                        <a href="https://firmfox.com/our-services" rel="no" target="_blank">
                         <Button  variant="outlined" sx={{
                        color: "#1E1D1B", border: "2px solid #1E1D1B", textTransform: "capitalize", fontSize: "17px", borderRadius: "30px", '&:hover': {
                            backgroundColor: '#1E1D1B',
                            color: "white",
                            border: "2px solid #1E1D1B",

                            }
                        }}>Find Out More</Button>
                        </a></div>
                    </div>
                </div>
        </div>
    );
}

export default NavbarNew;