import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { apiCallWithAxiosCancel, GetApplicationData, SaveTradingName } from "../../helpers/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import LoadingModal from '../../helpers/LoadingModal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';


Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
        const uniqueItems = new Set(list.map(mapper));
        if (uniqueItems.size !== list.length) {
            return this.createError({ message });
        }
        return true;
    });
});

// Add a method to check for case-insensitive uniqueness
Yup.addMethod(Yup.array, 'uniqueCaseInsensitive', function (message) {
    return this.test('unique', message, function (list) {
      const lowercasedList = list.map((item) => `${item}`?.trim()?.toLowerCase());
      const set = new Set(lowercasedList);
      const isUnique = set.size === list.length;
  
      if (!isUnique) {
        return this.createError({ message });
      }
  
      return true;
    });
  });


const validationSchema = Yup.object().shape({
    businessNames: Yup.array()
        .of(Yup.string().trim().required()) // Check if all names are required
        .min(3, 'You must provide at least 3 business names')
        .uniqueCaseInsensitive('Business names must be unique') // Ensure names are unique
});





function BusinessName({ onNext, onBack, onSaveAndExit, setMaxStepReached }) {
    const dispatch = useDispatch();
    const applicationID = useSelector((state) => state.app.applicationID);
    const [loading, setLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [initialBusinessNames, setInitialBusinessNames] = useState(['', '', '']);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchApplicationData() {
            try {
                setLoading(true);
                console.log("Fetching application data for ID:", applicationID);
                const ApplicationData = await GetApplicationData(applicationID, dispatch);
                console.log("Fetched Application Data:", ApplicationData);
                setMaxStepReached(ApplicationData?.data[0]?.step_no + 1)
                if (ApplicationData.data.length > 0 && ApplicationData.data[0]?.trade_name) {
                    const tradeNames = ApplicationData.data[0]?.trade_name?.map(nameObj => nameObj.name);
                    const namesToSet = [...tradeNames, '', '', ''].slice(0, 3);
                    setInitialBusinessNames(namesToSet);
                } else {
                    console.log("No trade names found in application data.");
                }
            } catch (error) {
                console.error("Error occurred while fetching Application Data:", error);
                toast.error("Error occurred while fetching Application Data");
            } finally {
                setLoading(false);
            }
        }

        if (applicationID) {
            fetchApplicationData();
        } else {
            console.error("No application ID found.");
        }
    }, [applicationID, dispatch]);

    const handleSubmit = async (values, exit) => {
        try {
            setNextLoading(true);
            const payload = {
                name: values.businessNames[0],
                name2: values.businessNames[1],
                name3: values.businessNames[2],
                id: applicationID,
            };
        // Use the cancellation logic for saving trading name
        await apiCallWithAxiosCancel((cancelToken) =>
        SaveTradingName(payload, dispatch, cancelToken)
      );            
      if (exit === true) {
                navigate("/");
            } else {
                onNext();
            }
        } catch (error) {
            if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
                console.error("Error saving business names:", error);
                toast.error("Error saving business names.");        
            }

        } finally {
            setNextLoading(false);
            // setSubmitting(false);
        }
    };

    const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

    useEffect(() => {
      const updateHeight = () => {
        setScreenHeight(window.innerHeight); // Update state when window is resized
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateHeight);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

    function hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    return (
        <>
            <LoadingModal open={loading} />
            <Formik
                initialValues={{ businessNames: initialBusinessNames }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, errors, touched, setFieldValue, validateForm, submitForm }) => (
                    <Form className='h-full'>
                        <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} sx={{ minHeight: "100%" }}>
                            <Box sx={{ px: 5 }} marginTop={2}>
                                <p className="font-bold text-2xl text-[#1E1D1B]">
                                    Proposed names for your business:
                                </p>

                                <div style={{height:"fit-content", maxHeight: `calc(${screenHeight}px - 90px - 20px - 32px - 32px - 70px)`, overflowY:"auto"}} className="overflow-y-auto pr-2 scrollbar">
                                    <p className="font-semibold mt-2 mb-1 text-lg text-[#1E1D1B]">
                                        Please provide 3 options, ranked in order of preference:
                                    </p>

                                    {values.businessNames.map((name, index) => { 
                                        return(
                                        <div key={index} className="flex gap-4 max-w-[600px] w-full mb-2 items-center">
                                            <p className="font-bold text-xl w-4 text-[#1E1D1B]"> {index + 1}.</p>
                                            <Field
                                                as={TextField}
                                                size="small"
                                                name={`businessNames.${index}`}
                                                placeholder={`Suggest business name ${index + 1}`}
                                                variant="outlined"
                                                className="w-3/4"

                                                error={(touched.businessNames?.[index] && !!errors.businessNames?.[index]) }

                                            />

                                        </div>
                                    )})}
                                    {/* <div className="text-red-600">
                                        <ErrorMessage name="businessNames" component="div" />
                                    </div> */}

                                    {/* Display a single error message for all the names */}
                                    {touched.businessNames && errors.businessNames && (
                                        <div className="text-red-600">
                                            {typeof errors.businessNames === 'string' ? errors.businessNames : 'Please enter valid name in all fields.'}
                                        </div>
                                    )}

                                    <hr className="mt-3" />

                                    <p className=" mt-2 font-normal text-xs text-[#8C8C8C]">
                                        Trade name should:
                                    </p>
                                    <ul className="ml-5 mt-1 font-normal text-xs text-[#8C8C8C]">
                                        <li className="list-disc">
                                            Be a proper name and free from obscene or indecent words
                                            and should not be offensive to the general public.
                                        </li>
                                        <li className="list-disc">
                                            Be distinct and not similar to existing brands in UAE, government projects, or international brands.
                                        </li>
                                        <li className="list-disc">
                                            Be logical and reflect and indicate your business activity.
                                        </li>
                                    </ul>

                                    <p className=" mt-2 font-normal text-xs text-[#8C8C8C]">
                                        Trade name should not:
                                    </p>
                                    <ul className="ml-5 mt-1 font-normal text-xs text-[#8C8C8C]">
                                        <li className="list-disc">
                                            Contain religious words such as ALLAH, LORD, GOD, or any other GOD’s name, etc.
                                        </li>
                                        <li className="list-disc">
                                            Carry any discrimination of caste, race, color, or ethnicity.
                                        </li>
                                        <li className="list-disc">
                                            Contain the name of any Emirate of UAE, city, country, government, or ministry.
                                        </li>
                                        <li className="list-disc">
                                            Begin with the word ‘Universal’ or ‘International’ in either of the languages, English or Arabic.
                                        </li>
                                        <li className="list-disc">
                                            Have any restricted names which include global political organizations or religious sectarian organizations, including such individuals or groups.

                                        </li>

                                    </ul>
                                    <p className=" mt-3 font-semibold text-sm text-[#8C8C8C]">
                                        *These names are subject to availability. You will be contacted if none of these are available.
                                    </p>
                                </div>



                            </Box>

                            <Box
                                sx={{
                                    alignItems: "flex-end",
                                    display: "flex",
                                    gap: "0.1rem",
                                    mt: 1,
                                    borderTop: "2px solid #EBEBE6"
                                }}
                            >
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "rgb(212 212 216)",
                                        fontWeight: 600,
                                        "&:hover": {
                                            backgroundColor: "#81EF6F",
                                            boxShadow: "none",
                                        },
                                        color: "#1E1D1B",
                                        borderRadius: "0px",

                                        boxShadow: "none",
                                        textTransform: "none",
                                        maxWidth: "28%",
                                    }}
                                    onClick={onBack}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "rgb(212 212 216)",
                                        fontWeight: 600,
                                        "&:hover": {
                                            backgroundColor: "#81EF6F",
                                            boxShadow: "none",
                                        },
                                        color: "#1E1D1B",
                                        borderRadius: "0px",
                                        boxShadow: "none",
                                        textTransform: "none",
                                        maxWidth: "28%",
                                    }}
                                    onClick={async () => {
                                        const errors = await validateForm();
                                        if (Object.keys(errors).length === 0) {
                                            handleSubmit(values, true);
                                        } else {
                                            submitForm();
                                        }
                                    }}
                                >
                                    Save & Exit
                                </Button>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#81ef6f",
                                        fontWeight: 600,
                                        color: "#1E1D1B",
                                        borderRadius: "0px",
                                        textTransform: "none",
                                        "&:hover": {
                                            backgroundColor: "#81EF6F",
                                            boxShadow: "none",
                                        },
                                        display: "flex",
                                        boxShadow: "none",
                                        gap: "10px",
                                    }}
                                    type="submit"
                                >
                                    Next Step
                                    {nextLoading && (
                                        <svg
                                            className='animate-spin w-6 h-6'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                        >
                                            <path d='M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z' />
                                        </svg>
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                )}
            </Formik >
        </>
    );
}

export default BusinessName;
