import React from 'react';
import ApplicationTable from './ApplicationTable';
function AdminMain() {
    return (
        <div>
            <ApplicationTable />
        </div>
    );
}

export default AdminMain;