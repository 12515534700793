import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  DialogActions,
  Modal
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InitialPartnerDetails from "./BusinessPages/IntialPartnerDetails";
import LegalTypePage from "./BusinessPages/LegalTypePage";
import BusinnessActivities from "./BusinessPages/BusinessActivities";
import SelectJurisdiction from "./BusinessPages/SelectJurisdiction";
import RequiredDocument from "./BusinessPages/RequiredDocument";
import FinalPayment from "./BusinessPages/FinalPayment";
import { useLocation } from "react-router-dom";
import ChatBot from "./BusinessPages/ChatBot"; // Import the ChatBot component
import BusinessName from "./BusinessPages/BusinessName";
import ConfirmDetails from "./BusinessPages/ConfirmDetails";
import UserChatModal from "../components/businessDashboard/UserChatModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetApplicationID, setApplicationID } from "../features/appSlice";
import { localStorageValueTester } from "../Utilities/UtilityFunctions";
import { UserFeedback } from "../helpers/api";
import { toast } from "react-toastify";

function MainActivity() {
  const navigate= useNavigate();
  const dispatch = useDispatch()
  const applicationID = useSelector((state) => state.app.applicationID);
  const location = useLocation();
  const queryParams = new URLSearchParams(
    location.search
  );
  const [open, setOpen] = useState(false);
  const [apiloading, setApiLoading] = useState(false);
  const [feedback, setFeedback] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      setApiLoading(true);
      // Replace with your API endpoint
      const payload={
        app_id: applicationID,
        feedback: feedback
      }
      console.log("payload of Zfar", payload)
      const response = await UserFeedback(payload,dispatch);
      setFeedback(''); // Clear the textarea after submission
      handleClose();
      toast.success('Feedback submitted successfully.',{toastId: "feedback_success"});
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error('An error occurred while submitting feedback.',{toastId: "feedback_error"});
    }
    finally{
      setApiLoading(false);
    }
  };
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const continueApplication = queryParams.get("continue");
  const newApplication = queryParams.get("new-application");

  var BusinessStep = 0;
  const progress_step = location?.state?.step;

  const [currentStep, setCurrentStep] = useState(
    BusinessStep && BusinessStep > 0
      ? BusinessStep - 1
      : 0
  );
  const [currentIndex, setcurrentIndex] =
    useState(
      BusinessStep && BusinessStep > 0
        ? BusinessStep - 1
        : 0
    );
    
    const isFirstRender = useRef(true);

    useEffect(() => {
    
      if (isFirstRender.current) {
        // Skip the effect on the first render
        isFirstRender.current = false;
        return;
      }
    
      // Run the effect after the first render
      if (newApplication === "true") {
        sessionStorage.setItem("current_step", `${currentStep}`);
      }
    
    }, [currentStep]);

  useEffect(()=>{
    if (applicationID?.length && localStorageValueTester(sessionStorage.getItem("current_step")) === true ){
      setCurrentStep(parseInt(sessionStorage.getItem("current_step")))
      setcurrentIndex(parseInt(sessionStorage.getItem("current_step")))
    }
    else{
      sessionStorage.setItem("current_step", "null")
    }
  },[])
    
  useEffect(()=>{

    // console.log("progress_step ===> main activity ", progress_step)
    // console.log("continue ===> main activity ", continueApplication)

    if ((continueApplication?.length && continueApplication === "true" && continueApplication !== null && continueApplication !== undefined)){
      if (progress_step){
        var BusinessStep = progress_step
        setCurrentStep(
          BusinessStep && BusinessStep > 0
            ? BusinessStep - 1
            : 0
        );
        setcurrentIndex(
          BusinessStep && BusinessStep > 0
            ? BusinessStep - 1
            : 0
        );
      }
      else{
        navigate("/")
      }
    }
    else if (applicationID?.length && localStorageValueTester(sessionStorage.getItem("current_step")) === true && newApplication === "true"){
      setCurrentStep(parseInt(sessionStorage.getItem("current_step")))
      setcurrentIndex(parseInt(sessionStorage.getItem("current_step")))
    }
    else if(applicationID?.length && (!newApplication || newApplication === null || newApplication === undefined) && newApplication !== "true"){
      dispatch(resetApplicationID())
      navigate("/")
    }
    else if(!applicationID && newApplication !== "true"){
      dispatch(resetApplicationID())
      navigate("/")
    }
    
  }, [])


  const [isSVGClicked, setIsSVGClicked] =
    useState(false);
  const [maxStepReached, setMaxStepReached] =
    useState(currentIndex); // Track maximum step reached

  const [openModal, setOpenModal] = useState(false);


  const stepTitles = [
    "Business Activities",
    "Shareholder(s) Details",
    "Legal Type",
    "Select Jurisdiction",
    "Business Name",
    "Required Documents",
    "Confirm Details",
    "Final Payment",
  ];

  const handleNext = () => {
    if (currentIndex < stepTitles.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setcurrentIndex(nextStep);
      if (nextStep > maxStepReached) {
        setMaxStepReached(nextStep); // Update max step reached
      }
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentStep(currentStep - 1);
      setcurrentIndex(currentIndex - 1);
    }
  };

  const handleSaveAndExit = () => {
    // Implement save and exit logic here
  };

  const handleSVGClick = () => {
    setIsSVGClicked(!isSVGClicked); // Toggle the state
  };




  const handleContactAgentClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const isContactAgentButtonDisabled = currentIndex === 0 && !applicationID;


  return (
    <div>
      <div
        className=" relative  2xl:mx-[100px] overflow-visible"
      >
        {isSVGClicked && (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                //  paddingBottom: "3%",
                 zIndex: 2,
                // py: 10,
                flexGrow: 1,
              }}
            >
              <ChatBot
                setIsSVGClicked={setIsSVGClicked}
              />
            </div>
          </>
        )}
        <div style={{ maxHeight:"calc(100vh - 130px)", minHeight:"calc(100vh - 130px)",}} className="flex gap-5">
          <div className="xl:block w-1/3 overflow-hidden hidden flex-col">
            <div
              className="flex flex-col w-full "
              style={{
                width: "100%",
                backgroundColor: "#FBFAFB",
                borderRadius: "20px",
                height: "100%",
                paddingRight: "10px",
                paddingTop: "16px"
              }}

            >
              <div style={{ padding: "0px 10px 10px 30px", }} className="w-full flex flex-col h-full">
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 700 }}
                  className="h-6"
                  gutterBottom
                  marginTop={2}
                  marginBottom={1}
                >
                  Business Setup
                </Typography>

                <div style={{ height:"85%", maxHeight: `calc(${screenHeight}px - 90px - 20px - 20px - 230px)`, overflowY:"auto"}} className="flex flex-col justify-between mt-3">
                  {stepTitles.map(
                    (title, index) => (
                      <div
                        key={index}
                        className="relative flex gap-3 h-full text-sm xl:text-base 2xl:text-xl text-[#1E1D1B]"
                      >
                        <div
                          className={`shrink-0 ${(currentStep > maxStepReached ? currentStep : maxStepReached) >=
                            index
                            ? "bg-customPurple"
                            : "bg-stone-200"
                            } rounded-full h-5 w-5`}
                        />
                        <div
                          onClick={() => {
                            if (
                              index <=
                              maxStepReached
                            ) {
                              setCurrentStep(
                                index
                              );
                              setcurrentIndex(
                                index
                              );
                            }
                          }}
                          className="flex-auto cursor-pointer "
                        >
                          {title}
                        </div>

                        {/* this handles the vertical lines */}
                        {index !==
                          stepTitles.length -
                          1 && (
                            <div
                              className={`absolute top-5 left-2 w-1 h-full`}
                              style={{
                                marginLeft: "1px",
                                backgroundColor:
                                  currentStep >
                                    index
                                    ? "#81EF6F"
                                    : "transparent",
                              }}
                            />
                          )}
                      </div>
                    )
                  )}
                </div>
              </div>

              <Box
                display="flex"
                gap="0.2rem"
                flexDirection="column"
                className=""
                sx={{
                  p: "10px 20px 10px 30px",
                  // height: "15em"
                }}
              >
                <Divider
                  sx={{
                    mt: "1rem",
                    mb: "0.5rem",
                  }}
                />
                <Button
                  color="primary"
                  fullWidth
                  onClick={()=>navigate('/foxchat')}
                  sx={{
                    mt: "0px",
                    mb: "0.3rem",
                    borderRadius: "6px",
                    border: "2px solid #1E1D1B",
                    textTransform: "none",
                    color: "white",
                    backgroundColor: "#1E1D1B",
                    '&:hover': {
                      backgroundColor: "#1E1D1B",
                      textTransform: "none",
                      color: "white",
                    }
                  }}

                >
                  Ask Fox Chat (Beta)
                </Button>

                <Button
                  color="primary"
                  fullWidth
                  sx={{
                    mt: "1px",
                    mb: "1rem",
                    borderRadius: "6px",
                    border: "2px solid #1E1D1B",
                    textTransform: "none",
                    color: "#1E1D1B",
                    // '&:disabled': {
                    //   color: "#1E1D1B",
                    // }
                  }}
                  onClick={isContactAgentButtonDisabled ? null : handleContactAgentClick}
                  disabled={isContactAgentButtonDisabled}
                >
                  Contact an Agent
                </Button>
                <p className={`${isContactAgentButtonDisabled ? ' text-neutral-400': 'text-[#1E1D1B]'} text-xs cursor-pointer mb-3 underline`} onClick={isContactAgentButtonDisabled ? null : handleClickOpen}>Do you have feedback so we can improve FirmFox?</p>

                <UserChatModal open={openModal} onClose={handleCloseModal} appId={applicationID} />
              </Box>

            </div>
          </div>
          <div className="w-full flex flex-col justify-normal relative">
            <Box
              sx={{
                width: "130px",
                height: "65px",
                backgroundColor: "#B9B1F7",
                position: "absolute",
                top: 30,
                right: -35, // Adjust this value to move the box further left
                borderTopLeftRadius: "300px",
                borderBottomLeftRadius: "300px",
                margin: "10px", // Change color and width as needed
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 2, // ensure the SVG is above the black background
                overflow: "hidden", // SVG and CloseIcon should always be visible
              }}
              onClick={handleSVGClick}
            >
              {isSVGClicked ? (
                <CloseIcon
                  className="ml-[-40px]"
                  style={{
                    color: "#1E1D1B",
                    zIndex: 1001,
                  }}
                  sx={{
                    zIndex: 1050,
                    fontSize: "30px",
                  }}
                />
              ) : (
                <div className="flex items-center gap-2">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                className="ml-1"
                >
                  <path
                    d="M27.6383 52.6375C26.9597 53.1168 26.0455 53.1168 25.3669 52.6375L8.55269 40.7767C3.17417 36.9866 -0.0170457 30.9057 -0.000220289 24.5183V24.3846C-0.0787388 13.9841 3.58359 1.8948 3.74063 1.38203C4.17248 -0.0169641 5.93914 -0.468431 6.99354 0.557124L20.0444 13.2093C20.409 13.5661 20.9025 13.7611 21.4129 13.7611H31.5923C32.1026 13.7611 32.5962 13.5605 32.9607 13.2093L46.0172 0.568271C47.0716 -0.45171 48.8383 -0.000243068 49.2645 1.39875C49.4216 1.90595 53.0783 13.9897 52.9998 24.4068C53.0334 30.85 49.8815 36.9476 44.5759 40.6931L27.6383 52.643V52.6375ZM7.53195 6.5321C7.13375 6.14751 6.46634 6.33702 6.33735 6.87209C5.27735 11.253 3.88084 18.2256 3.92571 24.3734V24.5239C3.90888 29.6517 6.48877 34.5342 10.8185 37.5941L25.3613 47.8553C26.0399 48.3346 26.9541 48.3346 27.6327 47.8553L42.2988 37.5105C46.5669 34.5008 49.0963 29.6015 49.0738 24.4013C49.1187 18.2424 47.7222 11.2697 46.6678 6.88881C46.5388 6.35374 45.8714 6.15866 45.4732 6.54324L34.5591 17.1109C34.1946 17.4621 33.7011 17.6627 33.1908 17.6627H21.8391C21.3288 17.6627 20.8353 17.4621 20.4708 17.1109L7.53195 6.5321Z"
                    fill="#1E1D1B"
                  />
                  <path
                    d="M22.6302 32.2439C22.6302 33.8101 21.5365 34.8524 19.9157 34.8524C18.2948 34.8524 17.2012 33.8101 17.2012 32.2439C17.2012 30.6777 18.2948 29.5908 19.9157 29.5908C21.5365 29.5908 22.6302 30.6777 22.6302 32.2439Z"
                    fill="#1E1D1B"
                  />
                  <path
                    d="M35.7986 32.2439C35.7986 33.8101 34.705 34.8524 33.0841 34.8524C31.4633 34.8524 30.3696 33.8101 30.3696 32.2439C30.3696 30.6777 31.4633 29.5908 33.0841 29.5908C34.705 29.5908 35.7986 30.6777 35.7986 32.2439Z"
                    fill="#1E1D1B"
                  />
                </svg>
                <p className="font-bold leading-none">Fox Chat<br/><span className="text-center font-normal leading-none">(beta)</span></p>
                </div>
              )}
            </Box>

            <div className=" flex-grow rounded-t-[20px] pt-5 bg-[#FFFFFF] h-full">
              {currentIndex === 0 && (
                <BusinnessActivities
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}
              {currentIndex === 1 && (
                <InitialPartnerDetails
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 2 && (
                <LegalTypePage
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 3 && (
                <SelectJurisdiction
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 4 && (
                <BusinessName
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 5 && (
                <RequiredDocument
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 6 && (
                <ConfirmDetails
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}

              {currentIndex === 7 && (
                <FinalPayment
                  setMaxStepReached={
                    setMaxStepReached
                  }
                  onNext={handleNext}
                  onBack={handleBack}
                  onSaveAndExit={
                    handleSaveAndExit
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog sx={{ '& .MuiDialog-paper': { minWidth: '45%', borderRadius: "20px", padding: "20px 20px" } }} open={open} onClose={handleClose}>
        <DialogTitle>
          <p className="text-lg font-bold">Please give an outline of your suggestions</p>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 14,
            }}
          >
            <CloseIcon sx={{fontSize: "30px", color: "#1E1D1B"}} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={6}
            fullWidth
            value={feedback}
            onChange={handleFeedbackChange}
            variant="outlined"
          />
          <div className="flex mt-3 justify-start otem">
        <Button
                  color="primary"
                  
                  onClick={handleSubmitFeedback}
                  sx={{
                    backgroundColor: "#81ef6f",
                    fontWeight: 400,
                    color: "#1E1D1B",
                    borderRadius: "6px",
                    textTransform: "none",
                    "&:hover": {
                        backgroundColor: "#81EF6F",
                        boxShadow: "none",
                    },
                    display: "flex",
                    boxShadow: "none",
                    px: "15px",
                }}
                endIcon={
                  apiloading && (
                    <svg
                      className="animate-spin w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )
                }

                >
                  Submit Feedback
                </Button>
        </div>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}

export default MainActivity;
