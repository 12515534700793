import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Container, Grid, Paper, TextField, Chip, Button, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { GetApplicationData, ChangeApplicationStatus } from "../helpers/api";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import LoadingModal from "../helpers/LoadingModal";
import { toast } from "react-toastify";
import ChatModal from "./components/ChatModal";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import MoreVertIcon from '@mui/icons-material/MoreVert';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CompleteDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatModalOpen, setChatModalOpen] = useState(false);
  const [status, setStatus] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileUrls, setFileUrls] = useState([]);
  const [jurisdiction, setJurisdiction] = useState();
  const [costing, setCosting] = useState();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await GetApplicationData(id, dispatch);
        const fileUrls = [];
        response?.data[0]?.partner_details?.forEach(partner => {
          partner.documents?.forEach(document => {
            // Push each URL into fileUrls array
            document.documents?.forEach(doc => {
              if (doc.url) {
                console.log("document url", doc.url);
                fileUrls.push(doc.url);
              }
            });
          });
        })
        setFileUrls(fileUrls);
        setJurisdiction(response?.data[0]?.jurisdiction?.[0]);
        setData(response?.data || []);
        setStatus(response?.data[0]?.agent_app_confirmation);
        setCosting(response?.data[0]?.costing);
        console.log("the images are ", response?.data[0]?.partner_details[0]?.documents[1]?.url)
        console.log(response);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Error fetching user data", {
          toastId: "get-user-data-error"
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // Check for query parameter
    const params = new URLSearchParams(location.search);
    if (params.get("chatModalOpen") === "true") {
      setChatModalOpen(true);
    }
  }, [id, dispatch, location.search]);

  const handleChangeApplicationStatus = async () => {
    if (id) {
      try {
        setLoading(true);
        const payload = {
          app_id: id,
          mark_as_complete: false,
        };

        await ChangeApplicationStatus(payload, dispatch);
        toast.success("Application status changed successfully");

        // Toggle status
        setStatus(!status);
      } catch (error) {
        console.error("Error changing application status:", error);
        toast.error("Error changing application status", {
          toastId: "error-app-save"
        });
      } finally {
        setLoading(false);
        handleMenuClose();
      }
    }
  };

  console.log("i have reached this step 1");
  const SubBusinessActivities = data[0]?.sub_activities || [];
  const LegalData = data[0]?.legal_data?.[0] || [];
  const PartnerDetails = data[0]?.partner_details || [];
  const TradeName = data[0]?.trade_name || "";
  console.log("business activities", SubBusinessActivities);

  console.log("i have reached this step 2");

  const LegalDataChip = [
    { label: LegalData?.nameEn },
  ];
  const validLegalChips = LegalDataChip?.filter(
    (chip) => chip?.label && chip.label.trim() !== ""
  );

  const [downloadingFiles, setDownloadingFiles] = useState(false)
  // Function to download all images for a partner and package them into a zip
  const downloadPartnerImages = async (partner) => {
    const zip = new JSZip();
    setDownloadingFiles(true); // Start loading state

    // Loop through the outer array
    for (const category of partner.documents) {
      // Loop through each 'documents' array in the category
      for (const document of category.documents) {
        try {
          const response = await fetch(document.url);
          if (!response.ok) {
            throw new Error(`Failed to fetch ${document.file_name}`);
          }

          const blob = await response.blob();

          // Extract the file name from the URL
          const urlParts = document.url.split('/');
          const extractedFileName = urlParts[urlParts.length - 1]; // Get the last part of the URL

          // Use the extracted file name if it is not undefined or empty
          const fileNameToUse = extractedFileName || document.file_name;

          zip.file(fileNameToUse, blob); // Add the file to the zip with the determined file name
          console.log(`Added ${fileNameToUse} to zip`);
        } catch (error) {
          console.error(`Error downloading ${document?.file_name}:`, error);
        }
      }
    }

    // Save the zip file after all documents are added
    try {
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${partner.nameEN}_documents.zip`);
      console.log(`Downloaded zip file for ${partner.nameEN}`);
    } catch (error) {
      console.error("Error saving zip file:", error);
      toast.error("Error saving zip file", {
        toastId: "error-save-zip"
      });
    } finally {
      setDownloadingFiles(false); // Reset loading state
    }
  };


  const downloadAllFilesAsZip = async () => {
    const zip = new JSZip();
    console.log("file urls inside the function ", fileUrls);
    for (const url of fileUrls) {
      try {
        const response = await fetch(url,{ mode: 'cors', cache: 'no-cache' });
        if (!response.ok) {
          console.error(`Failed to download ${url}`);
          continue;
        }
        const blob = await response.blob();
        const fileName = url.split('/').pop(); // Extracts the filename from the URL
        zip.file(fileName, blob);
      } catch (error) {
        console.error(`Error fetching ${url}:`, error);
      }
    }

    // Generate the zip file and prompt download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'files.zip'); // Save the zip file as 'files.zip'
    });
  };



  // Function to download all images for all partners
  const downloadAllAttachments = async () => {
    console.log("PartnerDetails = ", PartnerDetails)
    // Iterate through each partner
    for (const partner of PartnerDetails) {
      // Download all images for the current partner and package them into a zip
      await downloadPartnerImages(partner);
    }
  };

  const generatePDF = async () => {
    const pdf = new jsPDF();
    let y = 20; // initial y position for the PDF content
    const pageHeight = pdf.internal.pageSize.height;
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 15;
    const maxLineWidth = pageWidth - margin * 2; // maximum width for text
  
    PartnerDetails.forEach((partner, index) => {
      // Add partner header
      pdf.setFont("helvetica", "bold");
      pdf.text(`Partner Number ${index + 1}`, margin, y);
      y += 10;
  
      // Add partner details
      pdf.setFont("helvetica", "normal");
      pdf.text(`Name: ${partner.nameEN}`, margin, y);
      y += 10;
      pdf.text(`Nationality: ${partner.nationality_name}`, margin, y);
      y += 10;
      pdf.text(`Email: ${partner.email || "N/A"}`, margin, y);
      y += 10;
      pdf.text(`Phone Number: ${partner.phoneNumber || "N/A"}`, margin, y);
      y += 10;
      pdf.text(`Equity: ${partner.equity || "N/A"}%`, margin, y);
      y += 10;
  
      // Wrap and add Address 1
      const wrappedAddress1 = pdf.splitTextToSize(`Address: ${partner.address1 || "N/A"}`, maxLineWidth);
      wrappedAddress1.forEach(line => {
        if (y > pageHeight - 30) {
          pdf.addPage();
          y = 20;
        }
        pdf.text(line, margin, y);
        y += 10;
      });
  
      // Wrap and add Address 2
      if (partner.address2) {
        const wrappedAddress2 = pdf.splitTextToSize(`Address 2: ${partner.address2}`, maxLineWidth);
        wrappedAddress2.forEach(line => {
          if (y > pageHeight - 30) {
            pdf.addPage();
            y = 20;
          }
          pdf.text(line, margin, y);
          y += 10;
        });
      }
  
      // Wrap and add Address 3
      if (partner.address3) {
        const wrappedAddress3 = pdf.splitTextToSize(`Address 3: ${partner.address3}`, maxLineWidth);
        wrappedAddress3.forEach(line => {
          if (y > pageHeight - 30) {
            pdf.addPage();
            y = 20;
          }
          pdf.text(line, margin, y);
          y += 10;
        });
      }
  
      y += 10; // Add some space before the next section
  
      // Add Sub Business Activities
      if (SubBusinessActivities.length > 0) {
        pdf.setFont("helvetica", "bold");
        pdf.text("Sub Business Activities:", margin, y);
        y += 10;
  
        pdf.setFont("helvetica", "normal");
        SubBusinessActivities.forEach((activity, subIndex) => {
          const wrappedText = pdf.splitTextToSize(`${subIndex + 1}. ${activity.activityNameEn}`, maxLineWidth);
          wrappedText.forEach(line => {
            if (y > pageHeight - 30) {
              pdf.addPage();
              y = 20;
            }
            pdf.text(line, margin + 5, y);
            y += 10;
          });
        });
      }
  
      y += 10; // Space before the next section
  
      // Add Legal Types
      if (validLegalChips.length > 0) {
        pdf.setFont("helvetica", "bold");
        pdf.text("Legal Types:", margin, y);
        y += 10;
  
        pdf.setFont("helvetica", "normal");
        validLegalChips.forEach((chip, legalIndex) => {
          const wrappedText = pdf.splitTextToSize(`${legalIndex + 1}. ${chip.label}`, maxLineWidth);
          wrappedText.forEach(line => {
            if (y > pageHeight - 30) {
              pdf.addPage();
              y = 20;
            }
            pdf.text(line, margin + 5, y);
            y += 10;
          });
        });
      }
  
      y += 10; // Space before the next section
  
      // Add Business Names
      if (TradeName.length > 0) {
        pdf.setFont("helvetica", "bold");
        pdf.text("Business Names:", margin, y);
        y += 10;
  
        pdf.setFont("helvetica", "normal");
        TradeName.forEach((name, tradeIndex) => {
          const wrappedText = pdf.splitTextToSize(`${tradeIndex + 1}. ${name.name}`, maxLineWidth);
          wrappedText.forEach(line => {
            if (y > pageHeight - 30) {
              pdf.addPage();
              y = 20;
            }
            pdf.text(line, margin + 5, y);
            y += 10;
          });
        });
      }
  
      y += 20; // Add extra space before the next partner
    });
  
    // Save the PDF file
    pdf.save("partners-details.pdf");
  };
  
  
  
  
  console.log("is the user correct", data[0]?.is_completed);
  console.log("the file urls are ", costing);

  return (
    <>
      <ChatModal
        appId={id}
        open={chatModalOpen}
        onClose={() => setChatModalOpen(false)}
      />
      <LoadingModal open={loading} />
      <div className="" style={{ width: "100%", }}>
        <Container sx={{ width: "100%" }}>
          <Grid container sx={{ justifyContent: "center", width: "100%" }}>
            <Grid item sx={{ px: 1 }} xs={12} sm={12} md={12} lg={12}>

              <div className="border-2 border-[#FBFAFB] rounded-[20px] py-10  px-3 min-h-screen">
                {/* this is the complete details page {id} */}
                <div className="bg-[#81EF6F] w-full h-20 flex justify-between items-center">

                  <p className="col-span-2 text-center font-bold text-2xl p-4">Application Details</p>
                  <div>
                    {
                      data[0]?.is_completed === true ? (
                        <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          color="inherit"
                          onClick={handleMenuOpen}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : null
                    }
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleChangeApplicationStatus}>
                        {status ? "Mark application as incomplete" : "Mark application as complete"}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <Item>
                  <Grid sx={{ py: 3, px: 2 }} container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        id="outlined-basic"
                        value={PartnerDetails[0]?.nameEN}
                        label="Full Name"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        inputProps={{ readOnly: true }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        id="outlined-basic"
                        label="Email"
                        value={PartnerDetails[0]?.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <p className="text-2xl font-semibold text-start mb-4">
                          Selected Business Activities:
                        </p>
                      </div>
                      <div className="flex flex-wrap mb-5 gap-3 items-center">
                        {SubBusinessActivities?.map((activity) => (
                          <Chip
                            key={activity.id}
                            label={activity.activityNameEn}
                          />
                        ))}
                      </div>
                      <div>
                        <p className="text-2xl font-semibold text-start mb-4">
                          Legal Type:
                        </p>
                        <div className="flex flex-wrap mb-5 items-center gap-3">
                          {validLegalChips.map((chip, index) => (
                            <Chip key={index} label={chip.label} />
                          ))}
                        </div>
                      </div>
                      <div>
                        <p className="text-2xl font-semibold text-start mb-4">
                          Business Name:
                        </p>
                        <div className="flex flex-wrap mb-5 items-center gap-3">
                          <Chip label={`First Name: ${TradeName[0]?.name}`} />
                          <Chip label={`Second Name: ${TradeName[1]?.name}`} />
                          <Chip label={`Three Name: ${TradeName[2]?.name}`} />
                        </div>
                      </div>
                      <div>
                        <p className="text-2xl font-semibold text-start mb-4">
                          Jurisdiction Information:
                        </p>
                        {
                          costing && <div className="flex flex-wrap mb-5 items-center gap-3">
                            <Chip label={`Jurisdiction Name: ${jurisdiction?.nameEn}`} />
                            <Chip label={`Number of Investors Visa: ${costing[0]?.visa_for_investor}`} />
                            <Chip label={`Number of Manager Visa: ${costing[1]?.visa_for_manager}`} />
                            <Chip label={`Workspace Type: ${costing[5]?.workspace_type}`} />
                          </div>
                        }

                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div>
                        <p className="text-2xl font-semibold text-start mb-4">
                          Partners Information:{" "}
                        </p>
                      </div>
                    </Grid>
                    {PartnerDetails &&
                      PartnerDetails?.map((partner, index) => (
                        <>
                          <Grid item xs={12}>
                            <p className="text-xl font-semibold text-start mb-4">
                              Partner Number {index + 1}
                            </p>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={partner?.nameEN ? partner?.nameEN : "N/A"}
                              label="Partner Name"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={partner?.nationality_name ? partner?.nationality_name : "N/A"}
                              label="Partner Nationality"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={
                                partner?.email === null ? "N/A" : partner?.email
                              }
                              label="Partner Email"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={
                                partner?.phoneNumber === null
                                  ? "N/A"
                                  : partner?.phoneNumber
                              }
                              label="Phone Number"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={
                                partner?.equity === null ? "N/A" : partner?.equity
                              }
                              label="Equity in %"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={
                                partner?.address1 === null
                                  ? "N/A"
                                  : partner?.address1
                              }
                              label="Adress 1"
                              variant="outlined"
                            />
                          </Grid>
                          {partner?.address2 && (
                            <Grid item xs={12} md={4}>
                              <TextField
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                id="outlined-basic"
                                value={
                                  partner?.address2 === null
                                    ? "N/A"
                                    : partner?.address2
                                }
                                label="Adress 2"
                                variant="outlined"
                              />
                            </Grid>
                          )}
                          {partner?.address3 && (
                            <Grid item xs={12} md={4}>
                              <TextField
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                                id="outlined-basic"
                                value={
                                  partner?.address3 === null
                                    ? "N/A"
                                    : partner?.address3
                                }
                                label="Adress 3"
                                variant="outlined"
                              />
                            </Grid>
                          )}



                          <Grid item xs={12} md={4}>
                            <TextField
                              inputProps={{ readOnly: true }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              id="outlined-basic"
                              value={
                                partner?.hasBeenToUAE === null
                                  ? "N/A"
                                  : partner?.hasBeenToUAE
                              }
                              label="Has Been To UAE"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ width: "100%" }}>
                            <p className="text-2xl font-semibold text-start mb-4">
                              Documents:
                            </p>
                            <div className="flex gap-5 flex-col md:flex-row items-center justify-between mb-5 w-full">
                              <div className="flex w-full">
                                {

                                  partner?.documents[0] && partner?.documents[0]?.documents ?

                                    <div style={{ overflowX: "auto", overflowY: "hidden", maxWidth: "100%", }} className="scrollbar flex gap-5">

                                      {
                                        partner?.documents?.map((doc) => {
                                          return (<>



                                            {
                                              doc?.documents?.map((inner_doc) => (
                                                <div style={{ marginBottom: "25px" }} className="w-[250px] max-w-[250px]">
                                                  <Tooltip placement="top" title={inner_doc?.url?.split("/")[inner_doc?.url?.split("/")?.length - 1]}>
                                                    <p className="wrapped-text-custom cursor-pointer" style={{ fontSize: "16px", maxWidth: "190px", marginBottom: "15px", paddingLeft: "10px" }}> <b>File Name:</b> {inner_doc?.url?.split("/")[inner_doc?.url?.split("/")?.length - 1]}</p>
                                                  </Tooltip>

                                                  {`${inner_doc?.url}`?.includes(".pdf") ?

                                                    <img
                                                      src={"https://play-lh.googleusercontent.com/hoJp176QsxUS8k5O0i0VeX6V5QX1ywx90YxPckWjyjN7_qXwMBhSMdH6TkPwci9mkw"}
                                                      className="rounded-xl w-[230px] h-[230px] object-cover m-auto"
                                                      alt="passport copy"
                                                    />

                                                    :

                                                    <img
                                                      src={inner_doc?.url}
                                                      className="rounded-xl w-[230px] h-[230px] object-cover m-auto"
                                                      alt="passport copy"
                                                    />
                                                  }



                                                  <a
                                                    href={inner_doc.url}
                                                    download={inner_doc?.file_name}
                                                  >
                                                    <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">
                                                      {`${inner_doc?.file_name}`?.includes(".pdf") ? "Download PDF" : "Download Image"}
                                                    </button>
                                                  </a>

                                                </div>
                                              ))
                                            }


                                          </>)
                                        })
                                      }

                                    </div> : ""
                                }
                              </div>
                              <div>
                                {

                                  partner?.documents[1] && partner?.documents[1]?.url ? <div>
                                    <p>{partner?.documents[1]?.file_name}</p>
                                    <img
                                      src={partner?.documents[1]?.url}
                                      className="rounded-xl  w-96 h-96 object-cover"
                                      alt="ID"
                                    />
                                    <a
                                      href={partner?.documents[1]?.url}
                                      download={partner?.documents[1]?.file_name}
                                    >
                                      <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">
                                        Download Image
                                      </button>
                                    </a>
                                  </div> : ""
                                }
                              </div>

                              {partner?.documents[2] && partner?.documents[2]?.url ? <div>
                                <p>{partner?.documents[2]?.file_name}</p>
                                <img
                                  src={partner?.documents[2]?.url}
                                  className="rounded-xl  w-96 h-96 object-cover"
                                  alt="Profile Photo"
                                />
                                <a
                                  href={partner?.documents[2]?.url}
                                  download={partner?.documents[2]?.file_name}
                                >
                                  <button className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg">
                                    Download Image
                                  </button>
                                </a>
                              </div> : ""
                              }
                            </div>
                          </Grid>
                        </>
                      ))}
                  </Grid>
                </Item>
                <div
                  className="mt-10"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#81EF6F",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                      },
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      maxWidth: "28%",
                    }}
                    onClick={() => setChatModalOpen(true)}
                  >
                    Request Information
                  </Button>
                  <Button
                    disabled={downloadingFiles}
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#81EF6F",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                      },
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      maxWidth: "28%",
                    }}
                    onClick={downloadAllFilesAsZip}
                  >
                    Download All attach file
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#81EF6F",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#81EF6F",
                      },
                      color: "#1E1D1B",
                      borderRadius: "0px",
                      textTransform: "none",
                      maxWidth: "28%",
                    }}
                    onClick={generatePDF}
                  >
                    Download all data
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

    </>
  );
}

export default CompleteDetails;
