export function localStorageValueTester(val){
    if (val != null && val != "null" && val != undefined && val != "undefined"){
        return true;
    }
    else{
        return false
    }
}

export function cleanArray(arr) {
    return arr.map(obj => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== null && value !== '' && `${value}`?.trim() !== "" && `${value}`?.trim() !== "null" && `${value}`?.trim() !== "undefined")
        );
    });
}

export function cleanString(str) {
    if (str === null || str === undefined || str === '') {
        return '';
    }
    return `${str}`.trim();
}

export function cleanObject(obj) {
    // Create a new object by filtering out keys with unwanted values
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => {
        // Remove properties that are null, undefined, empty string, or only whitespace
        return value !== null && value !== undefined && value.toString().trim() !== '';
      })
    );
  }