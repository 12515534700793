import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SaveFinalPayment } from "../../helpers/api";
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


function StripePaymentConfirm() {
  // const applicationId = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiload, setApiload] = React.useState(false);

  const [queryparams]=useSearchParams();
  const app_id = queryparams.get('app_id');

  const ConfirmingFullProgress = async () => {
    setApiload(true);

    const jsonObj = {
      is_submitted: 1,
      id: app_id
    };
    try {
      setApiload(true);
      await SaveFinalPayment(jsonObj, dispatch);
      // navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
    }
    finally {
      setApiload(false);
    }
  };

  useEffect(() => {
    if (!app_id || app_id === null || app_id === undefined || app_id === "null" || app_id === "undefined"){
      navigate("/")
    } else {
      ConfirmingFullProgress();
    }
  }, []);
  return (
    <div
      className=" flex justify-center items-center 2xl:mx-[100px] "
    >
      <div className='bg-white my-20 rounded-2xl  p-5 px-10 w-[450px]  shadow pt-16 pb-8 flex flex-col gap-2 items-center justify-center'>
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.5 77C60.2107 77 77 60.2107 77 39.5C77 18.7893 60.2107 2 39.5 2C18.7893 2 2 18.7893 2 39.5C2 60.2107 18.7893 77 39.5 77Z" stroke="#1E1D1B" stroke-width="4" stroke-miterlimit="10" />
          <path d="M22 37.9605L31.8156 47.5384C33.8243 49.548 35.3344 49.4483 37.3146 47.4671L58 27" stroke="#1E1D1B" stroke-width="4" stroke-miterlimit="10" />
        </svg>

        <p className='text-3xl text-[#1E1D1B] font-bold'>You are all set!</p>
        <p className=' text-[#1E1D1B] text-center mb-8 mt-7'>Your payment is confirmed, you will receive an email confirmation soon and we will notify for further steps if required.</p>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            fontSize: "16px",
            backgroundColor: "#81ef6f",
            fontWeight: 600,
            color: "#1E1D1B",
            textTransform: "none",
            borderRadius: "8px",
            boxShadow: "none",
            py: 1,
            "&:hover": {
              backgroundColor: "#81EF6F",
              boxShadow: "none",
            },
            display: "flex",
            gap: "10px",
          }}
          onClick={() => navigate("/")}
        >
         Go to FirmFox Dashboard
          {apiload && (
            <svg
              className="animate-spin w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          )}
        </Button>
      </div>
    </div>
  )
}

export default StripePaymentConfirm
