import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { GetAllApplicationsForAgent, ChangeApplicationStatus } from '../helpers/api';
import LoadingModal from '../helpers/LoadingModal';
import { Button, Pagination, Container, Menu, IconButton, MenuItem } from '@mui/material';
import { format } from 'date-fns';
import Badge from '@mui/material/Badge';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(id, businessName, businessActivity, email, progress, stepTimeStamp, completed_at, messages, status) {
    return { id, businessName, businessActivity, email, progress, stepTimeStamp, completed_at, messages, status };
}


export default function ApplicationTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedId, setSelectedId] = useState(null); // Add state to track selected application
    const [status, setStatus] = useState(true);


    const handleMenu = (event, id) => {
        setAnchorEl(event.currentTarget); // Open the menu
        setSelectedId(id); // Store the selected application ID
    };

    const handleClose = () => {
        setAnchorEl(null); // Close the menu
        setSelectedId(null); // Reset selected application ID
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await GetAllApplicationsForAgent(page);
                // console.log("API Response:", response.data[1]);
                console.log("Api Response", response.data[0]); // Log the response to inspect its structures

                // Ensure data is in the expected format
                const fetchedData = response?.data?.results?.applications.map((item) => {
                    const tradeName = item?.trade_name?.[0]?.name || null;
                    const businessActivity = item?.main_business_activities?.[0]?.activityNameEn || 'N/A';
                    const email = item?.account_holder?.[0]?.user_email || 'N/A';
                    const completed_at = item?.completed_at || null;
                    const messages = item?.agent_messages;
                    const progress = item?.progress;
                    const stepTimeStamp = item?.step_no_timestamp;
                    const status = item?.agent_app_confirmation;  // status field
                    let businessName = tradeName || item?.account_holder?.[0]?.user_first_name || 'N/A';

                    return createData(item?.app_id, businessName, businessActivity, email, progress, stepTimeStamp, completed_at, messages, status);
                }) || [];
                setRows(fetchedData);


                console.log("rows data is this ", fetchedData);
                setRows(fetchedData);

                const resultsPerPage = 10;
                const totalPages = Math.ceil(response?.data?.count / resultsPerPage);
                setTotalPages(totalPages);
                console.log("the total application", totalPages);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
            finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [page]);

    const handleChangeApplicationStatus = async () => {
        if (selectedId) {
            try {
                setLoading(true);
                const payload = {
                    app_id: selectedId,
                    mark_as_complete: !status
                };
                const response = await ChangeApplicationStatus(payload, dispatch);
                toast.success("Application status changed successfully");

                // Update status in rows state
                setRows(prevRows => prevRows.map(row =>
                    row.id === selectedId ? { ...row, status: !row.status } : row
                ));

            } catch (error) {
                console.error("Error changing application status:", error);
                toast.error("Error changing application status");
            } finally {
                setLoading(false);
            }
        }
        handleClose();
    };



    const handleRowClick = (id, Modal) => {
        console.log("checkout this is the id ", id);
        navigate(`/application-details/${id}?chatModalOpen=${Modal}`);
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';

        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
            // Handle invalid date
            return 'Invalid date';
        }
        return format(date, 'p, MMM d, yyyy');
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };
    console.log(" the total pages are: ", rows[0]?.messages);
    return (
        <>
            <Container
                maxWidth="xl"
                sx={{
                    pb: 5
                }}
                disableGutters>
                <LoadingModal open={loading} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} aria-label="customized table">
                        <TableHead>
                            <TableRow sx={{width: "100%"}}>
                                <StyledTableCell sx={{width:"7%"}}>Business Name</StyledTableCell>
                                <StyledTableCell sx={{width:"30%"}} align="left">Business Activity</StyledTableCell>
                                <StyledTableCell sx={{width:"2%"}} align="left">Email</StyledTableCell>
                                <StyledTableCell sx={{width:"1%"}} align="left">Progress</StyledTableCell>
                                <StyledTableCell sx={{width:"7%"}} align="left">Updated at</StyledTableCell>
                                <StyledTableCell sx={{width:"2%"}} align="left">Details
                                </StyledTableCell>
                                <StyledTableCell sx={{width:"1%"}} align="left">Messages</StyledTableCell>
                                <StyledTableCell sx={{width:"1%"}} align="left">Status</StyledTableCell>
                                {/* <StyledTableCell align="left">Action</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row) => (
                                <StyledTableRow key={row.id} style={{ cursor: 'pointer' }}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.businessName}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{row.businessActivity}</StyledTableCell>
                                    <StyledTableCell align="left">{row.email}</StyledTableCell>
                                    <StyledTableCell align="left">{row.progress}%</StyledTableCell>
                                    <StyledTableCell align="left">{row.completed_at !== null ? formatDate(row.completed_at) : formatDate(row.stepTimeStamp)}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                backgroundColor: "rgb(212 212 216)",
                                                fontWeight: 500,
                                                "&:hover": {
                                                    backgroundColor: "#81EF6F",
                                                },
                                                borderRadius: "5px",
                                                color: "#1E1D1B",
                                                boxShadow: "none",
                                                textTransform: "none",
                                                maxWidth: "45%",
                                            }}
                                            onClick={() => handleRowClick(row.id, false)}
                                        >
                                            Details
                                        </Button>

                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <Badge badgeContent={row?.messages} color="primary">
                                            <CircleNotificationsIcon  onClick={() => handleRowClick(row.id, true)}/>
                                        </Badge>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {row.status ? (
                                            <HourglassEmptyIcon style={{ color: "orange" }} />
                                        ) : (
                                            <CancelIcon style={{ color: "red" }} />
                                        )}
                                    </StyledTableCell>

                                    {/* <StyledTableCell align="left">
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={(event) => handleMenu(event, row.id)} // Pass event and ID to handleMenu
                                            color="inherit"
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </StyledTableCell> */} 
                                </StyledTableRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                />
            </Container>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleChangeApplicationStatus}>Change Profile Status</MenuItem>
            </Menu>

        </>
    );
}
