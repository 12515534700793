import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Divider,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { CiCirclePlus } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import {
  apiCallWithAxiosCancel,
  GetApplicationData,
  GetNationalities,
  SaveIntialPartnerDetails,
} from "../../helpers/api";
import { toast } from "react-toastify";
import { Formik, FieldArray, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import LoadingModal from "../../helpers/LoadingModal";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

Yup.addMethod(Yup.number, "withinRange", function (min, max, message) {
  return this.test(
    "within-range",
    message,
    (value) => value >= min && value <= max
  );
});

const validationSchema = Yup.object().shape({
  partners: Yup.array()
    .of(
      Yup.object().shape({
        nameEN: Yup.string().trim().required("Partner name is required"),
        nationality_id: Yup.string().trim().required("Nationality is required"),
        dob: Yup.string().trim().required("Date of birth is required"),
        equity: Yup.number()
          .required("Equity is required")
          .withinRange(0.1, 100, "Equity must be between 1% and 100%"),
      })
    )
    .test(
      "total-equity",
      function (partners) {
        // Check if all required fields have values
        const allFieldsFilled = partners.every(
          (partner) => partner.nameEN && partner.nationality_id && partner.dob && partner.equity
        );

        if (!allFieldsFilled) {
          return true; // Let individual field errors handle missing fields
        }

        // Now perform the equity sum check
        const totalEquity = partners.reduce(
          (sum, partner) => sum + (partner.equity || 0),
          0
        );
        return totalEquity === 100 || this.createError({ message: "Sum of all partners' equity must be 100%" });
      }
    ),
});

function formatDOB(dob) {
  // Create a new Date object from the input string
  const date = new Date(dob);

  // Extract the day, month, and year
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

  // Return the formatted date
  return `${day}-${month}-${year}`;
}



// Example usage
// const dob = "2024-09-04";
// console.log(formatDOB(dob)); // Output: "04-09-24"



function InitialPartnerDetails({ onNext, onBack, setMaxStepReached, onSaveAndExit }) {
  dayjs.extend(customParseFormat);
  const applicationID = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.app);
  const inputRef = useRef(null);
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [country, setCountryNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [initialPartners, setInitialPartners] = useState([]);
  const [open, setOpen] = useState(false);
  const today = dayjs(); // Current date
  const twentyYearsAgo = today.subtract(20, 'year').toDate(); // Date for 20 years ago

  const [errorCustom, setErrorCustom] = useState({error: "", show: false, id: ""})

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    async function fetchNationalities() {
      try {
        setLoading(true);
        const Nationalities = await GetNationalities(dispatch);
        setCountryNames(Nationalities.data);
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: false, id: "nat-id"})
      } catch (error) {
        toast.error("Error occurred while fetching Countries Data");
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: true, id: "nat-id"})
      } finally {
        setLoading(false);
        setErrorCustom({error: "Error occurred while fetching Countries Data", show: false, id: "nat-id"})
      }
    }
    fetchNationalities();
  }, [dispatch]);

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        setLoading(true);
        const ApplicationData = await GetApplicationData(
          applicationID,
          dispatch
        );
        setMaxStepReached(ApplicationData?.data[0]?.step_no + 1)
        setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);
        console.log("application data of partner details", ApplicationData);
        if (
          ApplicationData.data.length > 0 &&
          ApplicationData.data[0].partner_details.length > 0
        ) {
          setInitialPartners(ApplicationData.data[0].partner_details);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      }
      finally {
        setLoading(false);
      }
    }
    fetchApplicationData();
  }, [applicationID, dispatch]);

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === 'next') {
      console.log("next clicked")
      onNext();
    } else {
      navigate("/");
    }
  };

  const handleSubmit = async (values, exit) => {
    try {
      setNextLoading(true);
      const payload = {
        partners: values.partners,
        id: applicationID,
      };
      await apiCallWithAxiosCancel((cancelToken) =>
        SaveIntialPartnerDetails(payload, dispatch, cancelToken)
      );
      if (exit === true) {
        navigate("/");
      } else {
        onNext();
      }
    } catch (error) {
      if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
        toast.error("Error saving Initial Partners Details.");
      }
    } finally {
      setNextLoading(false);
    }
  };



  return (
    <>
      <LoadingModal open={loading} />
      <Formik
        initialValues={{ partners: initialPartners }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values, false)}
        enableReinitialize
        validateOnChange={false} // Disable validation on change
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          validateForm,
          submitForm,
          setErrors
        }) => (
          <Form className="h-full">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"column"}
              sx={{ minHeight: "100%" }}
            >
              <Box sx={{ px: 5, width:"100%" }} marginTop={2}>
                <p className={`font-bold ${width <= 465 ? "text-md" : "text-2xl"}  text-[#1E1D1B]`}>
                  Who all are the shareholders of the business?
                </p>
                <br />

                <FieldArray name="partners">
                  {({ insert, remove, push }) => (
                    <div>
                      <div style={{ height: "fit-content", maxHeight: `calc(${screenHeight}px - 90px - 32px - 64px - 56px - 32px - 40px - 40px - 20px - 50px)`}} className="mb-3 overflow-auto scrollbar">
                        {values.partners.length > 0 &&
                          values.partners.map((partner, index) => (
                            <div className="mt-1 w-full" key={index}>
                              <span className="font-bold">
                                Partner {index + 1}
                              </span>
                              <div className="flex flex-col md:flex-row w-full pb-2 mt-1 gap-4 z-0 items-center">
                                <div className="w-full md:w-[40%] min-h-[55px]">
                                  <Field
                                    as={TextField}
                                    name={`partners.${index}.nameEN`}
                                    placeholder="Partner Name (as per passport)"
                                    variant="outlined"
                                    size="small"
                                    error={touched.partners?.[index]?.nameEN && !!errors.partners?.[index]?.nameEN}
                                    disabled={isEverythingDisabled}
                                    className="w-full md:w-[270px]"
                                  // helperText={
                                  //   touched.partners?.[index]?.nameEN && errors.partners?.[index]?.nameEN
                                  //     ? errors.partners?.[index]?.nameEN
                                  //     : ""
                                  // }
                                  />
                                  {/* Error message for name */}
                                  <ErrorMessage
                                    name={`partners.${index}.nameEN`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                  
                                </div>
                                <div className=" w-full  md:w-[40%] min-h-[55px]">
                                  <Field
                                    as={TextField}
                                    size="small"
                                    name={`partners.${index}.nationality_id`}
                                    select
                                    placeholder="Select nationality"
                                    variant="outlined"
                                    className="w-full md:w-[220px]"
                                    label="Nationality"
                                    disabled={isEverythingDisabled}
                                    error={touched.partners?.[index]?.nationality_id && !!errors.partners?.[index]?.nationality_id}
                                  // helperText={
                                  //   touched.partners?.[index]?.nationality_id && errors.partners?.[index]?.nationality_id
                                  //     ? errors.partners?.[index]?.nationality_id
                                  //     : ""
                                  // }
                                  >
                                    {country.map((country) => (
                                      <MenuItem key={country.id} value={country.id}>
                                        {country.nameEn}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                  {/* Error message for nationality */}
                                  <ErrorMessage
                                    name={`partners.${index}.nationality_id`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                </div>
                                <div className="w-full  md:w-[40%] min-h-[55px] ">
                                  <div className="lg:w-36 w-36">

                                  <DatePicker
                                    selected={partner.dob ? dayjs(partner.dob, 'DD-MM-YYYY').toDate() : null}
                                    onChange={(date) => {
                                      setFieldValue(
                                        `partners.${index}.dob`,
                                        date ? dayjs(date).format('DD-MM-YYYY') : ''
                                      );
                                    }} // Use null when date is cleared
                                    dateFormat="dd-MM-yyyy" // Make sure this is the correct format
                                    maxDate={twentyYearsAgo} // Users can't pick a date after 20 years ago
                                    minDate={new Date(1900, 0, 1)} // Optional: Set a reasonable minimum date
                                    showYearDropdown
                                    disabled={isEverythingDisabled}
                                    scrollableYearDropdown // Enables scrolling through years
                                    yearDropdownItemNumber={110} // Show a range of 100 years in the dropdown
                                    
                                    className="w-full border p-3 bg-transparent border-[#c1c0c1] h-[40px] rounded"
                                    placeholderText="Date of Birth"
                                  />

                                  </div>
                                  <ErrorMessage
                                    name={`partners.${index}.dob`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                </div>

                                <div className="w-full min-h-[55px]">
                                  <Field
                                    as={TextField}
                                    name={`partners.${index}.equity`}
                                    placeholder="Equity%"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    className="w-full md:w-[130px]"
                                    disabled={isEverythingDisabled}
                                    readOnly={isEverythingDisabled}
                                    error={
                                      touched.partners?.[index]?.equity &&
                                      !!errors.partners?.[index]?.equity
                                    }
                                    inputProps={{ min: "0", max: "100" }}
                                    onInput={(e) => {
                                      if (e.target.value < 0) e.target.value = 0;
                                      //if (e.target.value > 100) e.target.value = 100;
                                    }}
                                  // helperText={
                                  //   touched.partners?.[index]?.equity && errors.partners?.[index]?.equity
                                  //     ? errors.partners?.[index]?.equity
                                  //     : ""
                                  // }
                                  />
                                  <ErrorMessage
                                    name={`partners.${index}.equity`}
                                    component="div"
                                    className="text-red-500 text-xs"
                                  />
                                </div>
                                {
                                  isEverythingDisabled ? null : (
                                    <>
                                      {index !== 0 &&
                                        <IconButton
                                          onClick={() => index !== 0 && remove(index)}
                                        >
                                          {index !== 0 && <MdClose />}
                                        </IconButton>
                                      }
                                      {index === 0 &&
                                        <IconButton sx={{ color: "#fbfafb", pointerEvents: "none" }}
                                        >
                                          <MdClose />
                                        </IconButton>
                                      }
                                    </>
                                  )
                                }

                              </div>

                              {errors.partners?.[index] && touched.partners?.[index] && (
                                    <div className="flex gap-4 text-xs justify-center items-center" style={{ color: "red" }}>
                                      {/* Total equity error */}
                                      {errors.partners && typeof errors.partners === "string" && (
                                        <div className="text-red-500 text-xs">{errors.partners}</div>
                                      )}
                                    </div>
                                  )}
                            </div>
                          ))}
                      </div>

                      {
                        errorCustom?.show === true ? <div className="error-msg-div"><p className="error-msg-text">{errorCustom?.error}</p></div> : ""
                      }

                      <Divider sx={{ borderBottomWidth: 2 }}></Divider>
                      <div className={`border-2 mt-3 max-w-[530px] w-[100%] rounded-lg overflow-hidden ${isEverythingDisabled ? 'bg-[#e6e6e6] ' : 'bg-[#E6FCE2] border-[#81EF6F]'}  `}>
                        <button
                          type="button"
                          disabled={isEverythingDisabled}
                          className="flex items-center text-center w-[100%] justify-center py-3 px-4 cursor-pointer"
                          onClick={() => {
                            if (values.partners.length < 5) {
                              push({
                                nameEN: "",
                                nationality_id: "",
                                dob: "",
                                equity: "",
                              });
                            } else {
                              toast.warn("You can add up to 5 partners only.", {
                                toastId: "customId"
                              });
                            }
                          }}
                        >
                          <div className="flex items-center">
                            <CiCirclePlus
                              className={`mr-2 text-[#1E1D1B]`}
                              size={28}
                            />
                            <span className={` ${width <= 465 ? "text-sm" : "text-lg"} text-[#1E1D1B]`}>
                              Click to Add Another Partner
                            </span>
                          </div>

                        </button>
                      </div>
                      <div className="flex items-end ">
                        <p className="font-bold text-[#8C8C8C] text-sm mt-3">*Partner details should be added as shown on passport</p>
                      </div>
                    </div>

                  )}
                </FieldArray>

              </Box>

              <Box
                sx={{
                  alignItems: "flex-end",
                  display: "flex",
                  gap: "0.1rem",
                  mt: 0.5,
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={onBack}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={async () => {
                    if (isEverythingDisabled) {
                      console.log("i am here")
                      handleIsEverythingDisabled('exit');
                    } else {
                      console.log("i am hereeedsdsd")
                      const errors = await validateForm();
                      if (Object.keys(errors).length === 0) {
                        handleSubmit(values, true);
                      } else {
                        submitForm();
                      }
                    }
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#81ef6f",
                    fontWeight: 600,
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    display: "flex",
                    gap: "10px",
                  }}
                  type={isEverythingDisabled ? "button" : "submit"} // Correctly apply the conditional type here
                  onClick={() => {
                    if (isEverythingDisabled) {
                      handleIsEverythingDisabled('next');
                    }
                  }}
                >
                  Next Step
                  {nextLoading && (
                    <svg
                      className="animate-spin w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default InitialPartnerDetails;
