import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SaveFinalPayment } from "../../helpers/api";
import { Button } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

function StripeFailedPayment() {
  const applicationId = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiload, setApiload] = React.useState(false);
  const ConfirmingFullProgress = async () => {
    setApiload(true);

    const jsonObj = {
      is_submitted: 1,
      id: applicationId
    };
    try {
      setApiload(true);
      await SaveFinalPayment(jsonObj, dispatch);
      navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
    }
    finally {
      setApiload(false);
    }
  };
  return (
    <div
      className=" flex justify-center items-center 2xl:mx-[100px] "
    >
      <div className='bg-white my-20 rounded-2xl  p-5 px-10 w-[450px]  shadow pt-16 pb-8 flex flex-col gap-2 items-center justify-center'>
        <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M39.5 77C60.2107 77 77 60.2107 77 39.5C77 18.7893 60.2107 2 39.5 2C18.7893 2 2 18.7893 2 39.5C2 60.2107 18.7893 77 39.5 77Z" stroke="#141E14" stroke-width="4" stroke-miterlimit="10" />
          <path d="M27 53L53.0001 27" stroke="#141E14" stroke-width="4" stroke-miterlimit="10" />
          <path d="M27 27L53 53.0001" stroke="#141E14" stroke-width="4" stroke-miterlimit="10" />
        </svg>



       
        <p className='text-2xl mt-4 lg:text-3xl text-[#1E1D1B] font-bold'>Payment Failed</p>
        <p className=' text-[#1E1D1B] text-center mb-10'>Unfortunately, your payment failed.<br/>Click below to return to FirmFox and try again.</p>
        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            fontSize: "16px",
           backgroundColor: "#81EF6F",
            fontWeight: 600,
            color: "#141E14",
            textTransform: "none",
            borderRadius: "8px",
            boxShadow: "none",
            py: 1,
            "&:hover": {
             backgroundColor: "#81EF6F",
              boxShadow: "none",
            },
            display: "flex",
            gap: "10px",
          }}
          onClick={() => navigate("/")}
        >
          Go to Dashboard
          {apiload && (
            <svg
              className="animate-spin w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          )}
        </Button>
      </div>
    </div>
  )
}

export default StripeFailedPayment
