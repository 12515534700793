import React, { useEffect, useState } from "react";
import { Button, Box, TextField, Grid, MenuItem, Tooltip, DialogContent, Modal, Divider } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoadingModal from "../../helpers/LoadingModal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { SavePartnerDetails, GetApplicationData, apiCallWithAxiosCancel } from "../../helpers/api";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import ClearIcon from '@mui/icons-material/Clear';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { cleanArray } from "../../Utilities/UtilityFunctions";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const validationSchema = Yup.object().shape({
  partners: Yup.array().of(
    Yup.object().shape({
      phoneNumber: Yup.string().required("Phone number is required").min(9, "Phone number must be at least 9 digits")
      .max(16, "Phone number must not exceed 15 digits"),
      email: Yup.string().trim()
      .email("Invalid email address")  // Yup's built-in email validation
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for stricter validation
        "Invalid email address"
      )
      .required("Email is required"),
      address1: Yup.string().trim().trim().required("Residential Address 1 is required"),
      locatedInUAE: Yup.string().trim()
        .required("This field is required"),
      hasBeenToUAE: Yup.string().trim().when("locatedInUAE", {
        is: "false", // Assuming `locatedInUAE` is a string "true" or "false"
        then: schema => schema.required("This field is required"),
        otherwise: schema => schema.notRequired(),
      }),
    })
  ),
});


const RequiredDocument = ({ onNext, onBack, onSaveAndExit, setMaxStepReached }) => {
  const applicationID = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(0);
  const [isInsideUAE, setIsInsideUAE] = useState("");
  const [step, setStep] = useState(0);
  const [em, setem] = useState(false);
  const [idCardError, setIdCardError] = useState("");
  const [initialValues, setInitialValues] = useState();


  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handlePartnerChange = (index) => {
    setSelectedPartnerIndex(index);
  };

  const handleInsideUAEChange = (event) => {
    setIsInsideUAE(event.target.value);
  };

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        setLoading(true);
        const ApplicationData = await GetApplicationData(applicationID, dispatch);
        console.log("the data of the application is In the Partners: ", ApplicationData?.data[0]?.partner_details?.documents);
        setStep(ApplicationData?.data[0]?.step_no);

        if (ApplicationData.data.length > 0 && ApplicationData.data[0].partner_details.length > 0) {
          setPartners(ApplicationData.data[0].partner_details);
          setInitialValues(ApplicationData.data[0].partner_details);
          const initialPhoneNumbers = ApplicationData.data[0].partner_details.map(
            partner => partner.phoneNumber
          );
          setPhoneNumbers(initialPhoneNumbers);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      } finally {
        setLoading(false);
      }
    }
    fetchApplicationData();
  }, [applicationID, dispatch]); // Only depend on necessary variables


  const handleChangePhoneNumber = (newValue, partnerIndex, setFieldValue,allDetails) => {
    console.log("newValue:", newValue, "partnerIndex:", partnerIndex);
    console.log("allDetails:", allDetails);

    // Update the phone number in Formik's state
    setFieldValue(`partners[${partnerIndex}].phoneNumber`, newValue);
    if (!allDetails || !allDetails.countryCode) {
      setFieldValue(`partners[${partnerIndex}].phoneNumber`, ''); // Clear the field or show an error
      // You can also use Formik's setFieldError to set a custom error message
    }
    // Optionally update other states if needed, but Formik's state is the source of truth
  };


  const handleSubmit = async (values, exit) => {
    try {
      setNextLoading(true);

      // Set form values to avoid clearing fields on validation errors
      console.log("i am inside values:", values);
      //setInitialValues(values);

      const hasMissingIDCard = values.partners.some(
        (partner) => !partner.id_card_files || !partner.passport_files || !partner.photo_file
      );

      if (step <= 4 && hasMissingIDCard) {
        console.log("why i am getting triggered");
        setIdCardError("All Documents are required for all partners");
        return;
      }

      const transformedPartners = values.partners.map((partner) => {
        // Create a new object to hold transformed id_card_files
        const newPartner = { ...partner };

        if (newPartner.id_card_files && newPartner.id_card_files.length > 0) {
          newPartner.id_card_file = newPartner.id_card_files[0] || null;
          if (newPartner.id_card_files.length > 1) {
            newPartner.id_card_file_second = newPartner.id_card_files[1] || null;
          }
          // Add more keys if you expect more files
        } else {
          newPartner.id_card_file = null;
          newPartner.id_card_file_second = null;
        }

        if (newPartner.passport_files && newPartner.passport_files.length > 0) {
          newPartner.passport_file = newPartner.passport_files[0] || null;
          console.log("I am in the images section for passport file");
          if (newPartner.passport_files.length > 1) {
            newPartner.passport_file_second = newPartner.passport_files[1] || null;
          }
        } else {
          console.log("I am in the images section for passport file bullshoit");
          newPartner.passport_file = null;
          newPartner.passport_file_second = null;
        }

        // if (newPartner.photo_files && newPartner.photo_files.length > 0) {
        //   newPartner.photo_file = newPartner.photo_files[0] || null;
        //   if (newPartner.photo_files.length > 1) {
        //     newPartner.photo_file_second = newPartner.photo_files[1] || null;
        //   }
        // } else {
        //   newPartner.photo_file = null;
        //   newPartner.photo_file_second = null;
        // }

        // Remove the original id_card_files array
        delete newPartner.id_card_files;
        delete newPartner.passport_files;
        // delete newPartner.photo_files;

        return newPartner;
      });



      const filteredPayload = cleanArray(transformedPartners)

      const payload = {
        partners: filteredPayload,
        id: applicationID,
      };

    // Use the cancellation logic for saving partner details
   await apiCallWithAxiosCancel((cancelToken) =>
      SavePartnerDetails(payload, dispatch, cancelToken)
    );
      if (exit === true) {
        navigate("/");
      } else {
        onNext();
      }
    } catch (error) {
      if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
      console.error("Error saving Initial Partners Details:", error);
      toast.error("Error saving Initial Partners Details.");        
      }

    } finally {
      setNextLoading(false);
    }
  };

  useEffect(() => {
    // Check if the id_card_file is null or not
    if (partners[0]?.id_card_file === null) {
      setem(true);
    } else {
      setem(false);
    }
  }, [partners[0]?.id_card_file]);

  useEffect(() => {
    console.log("idCardError updated:", idCardError);
  }, [idCardError]);
  const VALID_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
  const VALID_DOCUMENT_TYPES = ['application/pdf'];
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes

  console.log("the data of index is : ", selectedPartnerIndex)
  console.log("the length of th paenter is : ", partners.length)
  console.log("the data of the partners is : ", initialValues)
  return (
    <>
      <Formik
        initialValues={{
          partners: initialValues || [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      // validateOnChange={false}  // Adjust these as needed
      // validateOnBlur={false}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          validateForm,
          submitForm,
          setFieldTouched
        }) => (
          <Form className="h-full flex flex-col justify-between">
            <LoadingModal open={loading} />
            <div style={{maxHeight: `calc(${screenHeight}px 20px - 90px - 20px - 120px)`, height:"fit-content"}} className=" pr-3 overflow-y-auto scrollbar h-full px-10 ">
              {values.partners.length > 0 &&
                values.partners.map((partner, index) => (
                  <>
                    <p className="font-bold pt-5 text-2xl text-[#1E1D1B]">
                      Partner {index + 1} - Required Information
                    </p>
                    <Box key={partner.id} sx={{ mt: 2 }} >
                      <div className="flex flex-wrap gap-4 items-center w-[100%]">
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                              border: "none"
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "35%",
                            border: "none"

                          }}
                          inputProps={{ border: "none" }}
                          value={partner.nameEN}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[200px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.nameEN}
                        </div>
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "30%"
                          }}
                          value={partner.nationality_name}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[150px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.nationality_name}
                        </div>

                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "20%"
                          }}
                          value={partner.dob}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[150px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.dob}
                        </div>
                        {/* <TextField
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#1E1D1B",
                            },
                            background: "#EBEBE6",
                            borderRadius: "6px",
                            width: "15%"
                          }}
                          value={partner.equity}
                          disabled
                          fullWidth
                          margin="normal"
                        /> */}
                        <div className="w-[100px] bg-[#EBEBE6] h-[55px] px-4 flex justify-start items-center rounded-md text-[#1E1D1B]  font-normal">
                          {partner.equity} %
                        </div>
                       
                      </div>
                      <div className="flex gap-2 md:flex-row flex-col  mt-3 w-11/12">
                        <div className=" w-full pb-5">
                          <p className="text-base font-semibold">Phone Number</p>
                          <MuiTelInput
                            name={`partners[${index}].phoneNumber`}
                            fullWidth
                            forceCallingCode
                            disableFormatting 
                            onBlur={() => setFieldTouched(`partners[${index}].phoneNumber`, true)}
                            value={partner.phoneNumber || "+971"}
                            onChange={(newValue,allDetails) => handleChangePhoneNumber(newValue, index, setFieldValue,allDetails)}

                          />
                          <ErrorMessage
                            className="text-red-500 mt-2 text-xs"
                            name={`partners[${index}].phoneNumber`}
                            component="div"
                          />
                        </div>
                        <div className=" w-full">
                          <p className="text-base font-semibold">Email</p>
                          <Field
                            name={`partners[${index}].email`}
                            as={TextField}
                            fullWidth
                            placeholder="Type here"
                            marginBottom="normal"
                            error={
                              touched.partners?.[index]?.email &&
                              !!errors.partners?.[index]?.email
                            }
                          />
                          <div className="">
                            <ErrorMessage
                              className="text-red-500 mt-2 text-xs"
                              name={`partners[${index}].email`}
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="text-base font-semibold mt-8 md:mt-0">
                        Residential address
                      </p>
                      <div className="flex gap-2 md:flex-row flex-col w-11/12">
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address1`}
                            as={TextField}
                            placeholder="Residental Address 1"
                            fullWidth
                            marginBottom="normal"
                            error={
                              touched.partners?.[index]?.address1 &&
                              !!errors.partners?.[index]?.address1
                            }
                          />

                          <ErrorMessage
                            className="text-red-500   mt-2 text-xs"
                            name={`partners[${index}].address1`}
                            component="div"
                          />
                        </div>
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address2`}
                            as={TextField}
                            placeholder="Residental Address 2"
                            fullWidth
                            marginBottom="normal"
                          />
                          <ErrorMessage
                            className="text-red-500 mt text-xs"
                            name={`partners[${index}].address2`}
                            component="div"
                          />
                        </div>
                        <div className="w-full">
                          <Field
                            name={`partners[${index}].address3`}
                            as={TextField}
                            placeholder="Residental Address 3"
                            fullWidth
                            marginBottom="normal"
                          />
                          <ErrorMessage
                            className="text-red-500 mt-2 text-xs"
                            name={`partners[${index}].address3`}
                            component="div"
                          />
                        </div>
                      </div>

                      <Grid item xs={12} mt={2} mb={2}>
                        <div>
                          <div className="flex gap-5 items-center">
                            <p className="text-base font-normal pr-1">
                              Is {" "}
                              <span className="font-semibold">
                                {partner.nameEN}
                              </span>{" "} a resident of the UAE?
                            </p>
                            <Field
                              name={`partners[${index}].locatedInUAE`}
                              as={TextField}
                              select
                              margin="normal"
                              placeholder="Yes/No"
                              sx={{
                                minWidth: "100px" // Adjust the value as needed
                              }}
                              value={values.partners[index].locatedInUAE || ""} // Handle null value
                              onChange={(e) => {
                                setFieldValue(`partners[${index}].locatedInUAE`, e.target.value);
                              }}
                              error={
                                touched.partners?.[index]?.locatedInUAE &&
                                !!errors.partners?.[index]?.locatedInUAE
                              }
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Field>
                            <ErrorMessage
                              className="text-red-500 mt-2 text-xs"
                              name={`partners[${index}].locatedInUAE`}
                              component="div"
                            />
                          </div>

                          {values.partners[index].locatedInUAE === "false" && (
                            <div className="flex gap-3 items-center mt-2 ">
                              <p className="text-base font-normal  pr-2 ">
                                Has {" "}
                                <span className="font-semibold">
                                  {partner.nameEN}
                                </span>{" "}
                                been to UAE before?
                              </p>
                              <Field
                                name={`partners[${index}].hasBeenToUAE`}
                                as={TextField}
                                select
                                margin="normal"
                                placeholder="Yes/No"
                                sx={{ minWidth: "100px" }}
                                error={
                                  touched.partners?.[index]?.hasBeenToUAE &&
                                  !!errors.partners?.[index]?.hasBeenToUAE
                                }
                              >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                              </Field>
                              <ErrorMessage
                                className="text-red-500 mt-2 text-xs"
                                name={`partners[${index}].hasBeenToUAE`}
                                component="div"
                              />
                            </div>
                          )}
                        </div>

                      </Grid>

                      <div>

                        <Modal open={open} onClose={!open}>
                          <div
                            style={{
                              position: "absolute",
                              top: "90px",
                              left: "50%",
                              transform: "translate(-50%, -5px)",
                              height: "84%",
                            }}
                            className="min-w-[90%] flex flex-col text-[#1E1D1B] scrollbar justify-between  overflow-y-auto  md:min-w-[70%] lg:min-w-[60%] lg:max-w-[60%] rounded-[20px] bg-white shadow-md "
                          >
                            <svg onClick={() => { setSelectedPartnerIndex(selectedPartnerIndex > 0 ? selectedPartnerIndex - 1 : selectedPartnerIndex) }} className={` cursor-pointer absolute top-[40%] left-4`} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="54" y="54" width="54" height="54" rx="27" transform="rotate(-180 54 54)" fill="#EBEBE6" />
                              <path d="M25 21L19 27L25 33" stroke="#3A3937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M35 26.25C35.4142 26.25 35.75 26.5858 35.75 27C35.75 27.4142 35.4142 27.75 35 27.75L35 26.25ZM20 26.25L35 26.25L35 27.75L20 27.75L20 26.25Z" fill="#3A3937" />
                            </svg>


                            <svg onClick={() => { setSelectedPartnerIndex(partners.length - 1 > selectedPartnerIndex ? selectedPartnerIndex + 1 : selectedPartnerIndex) }} className={`cursor-pointer absolute top-[40%] right-4 z-10`} width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect width="54" height="54" rx="27" fill="#EBEBE6" />
                              <path d="M29 22L35 28L29 34" stroke="#3A3937" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M19 27.25C18.5858 27.25 18.25 27.5858 18.25 28C18.25 28.4142 18.5858 28.75 19 28.75L19 27.25ZM34 27.25L19 27.25L19 28.75L34 28.75L34 27.25Z" fill="#3A3937" />
                            </svg>

                            <Box className="md:px-16 flex flex-col justify-end md:pt-8 p-3">
                              <ClearIcon
                                onClick={() => setOpen(false)}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "15",
                                  right: "15",
                                }}
                                className="w-6 h-6 text-[#000000]"
                              />

                              <DialogContent sx={{ padding: "0px", mt: 0 }}>
                                <div className="relative ">
                                  {values.partners.length > 0 && (
                                    <>
                                      <div className="px-10 mt-4">
                                        <p className="font-semibold text-2xl">Document Upload - {values.partners[selectedPartnerIndex]?.nameEN}</p>

                                        {/* <div className="max-w-[290px] bg-[#EBEBE6] rounded-md px-3 mt-3 py-3">{values.partners[selectedPartnerIndex]?.nameEN}</div> */}
                                      </div>
                                      <div className="flex flex-col mt-[5%] px-10 justify-end ">
                                        <div className="flex flex-col lg:flex-row gap-5 justify-end items-start mt-5">
                                          <div className="w-full lg:w-[33.333333%]">
                                            <p className="font-semibold wrapped-text-custom max-w-[100%]">National ID <span className="text-xs font-normal italic ml-1">(<span className="text-red-500">*</span>if you have one)</span></p>
                                            <label
                                              onDragOver={(e) => e.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const files = Array.from(event.dataTransfer.files);

                                                const validFiles = files.filter(file => {
                                                  const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                  const isValidFileSize = file.size <= MAX_FILE_SIZE;
                                                  return isValidFileType && isValidFileSize;
                                                });

                                                if (validFiles.length > 0) {
                                                  const currentFiles = values.partners[selectedPartnerIndex]?.id_card_files || [];
                                                  const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last two files
                                                  setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                  //setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, [...(values.partners[selectedPartnerIndex]?.id_card_files || []), ...validFiles]);
                                                } else {
                                                  toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                }
                                              }}

                                              htmlFor={`partners.${selectedPartnerIndex}.id_card_file`}
                                              className="h-[70%] border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg py-8"
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                multiple
                                                id={`partners.${selectedPartnerIndex}.id_card_file`}
                                                name={`partners.${selectedPartnerIndex}.id_card_file`}
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                onChange={(event) => {
                                                  const files = Array.from(event.currentTarget.files); // Convert FileList to an array
                                                  if (files.length === 0) return;

                                                  // Filter valid files by type and size
                                                  const validFiles = files.filter(file => {
                                                    const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                    const isValidFileSize = file.size <= MAX_FILE_SIZE; // Assuming MAX_FILE_SIZE is defined (e.g., 5 * 1024 * 1024 for 5MB)
                                                    return isValidFileType && isValidFileSize;
                                                  });

                                                  if (files.length > 2) {
                                                    toast.error("You can only select up to 2 files.", {toastId: "NationalID-file-limit"});
                                                    event.target.value = ''; // Reset input field if more than 2 files selected
                                                    return;
                                                  }

                                                  if (validFiles.length > 0) {
                                                    // Get the current files and update the field with the new valid files, keeping only the last two
                                                    const currentFiles = values.partners[selectedPartnerIndex]?.id_card_files || [];
                                                    const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last 2 files

                                                    setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                  } else {
                                                    toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                  }
                                                }}
                                              />

                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    values.partners?.[selectedPartnerIndex]?.id_card_file
                                                      ? values.partners[selectedPartnerIndex].id_card_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[0]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[0].file_name
                                                        : "Click to Upload ID"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>
                                            {values.partners?.[selectedPartnerIndex]?.id_card_files?.length > 0 ? (
                                              <div className="flex flex-col justify-center items-center gap-4 mt-2">
                                                {values.partners[selectedPartnerIndex]?.id_card_files.map((file, index) => (
                                                  <div key={index} className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                    <p className="text-sm font-thin line-clamp-1">
                                                      {file.name}
                                                    </p>
                                                    <ClearIcon
                                                      className="cursor-pointer text-[#1E1D1B]"
                                                      sx={{ fontSize: "20px" }}
                                                      onClick={() => {
                                                        const updatedFiles = values.partners[selectedPartnerIndex].id_card_files.filter((_, i) => i !== index);
                                                        setFieldValue(`partners[${selectedPartnerIndex}].id_card_files`, updatedFiles);
                                                      }}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                                ?.filter(category => category.category_id === 1)
                                                ?.map((category) => (
                                                  category?.documents?.length > 0 && (
                                                    category.documents.map((doc, docIndex) => (
                                                      <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                        <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                          previous image: {doc.file_name}
                                                        </a>
                                                      </div>
                                                    ))
                                                  )
                                                ))
                                            )}


                                          </div>

                                          <div className="w-full lg:w-1/3">
                                            <p className="font-semibold">Passport <span className="text-red-500">*</span></p>
                                            <label
                                              onDragOver={(event) => event.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const files = Array.from(event.dataTransfer.files);
                                                const validFiles = files.filter(file => {
                                                  const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                  const isValidFileSize = file.size <= MAX_FILE_SIZE;
                                                  return isValidFileType && isValidFileSize;
                                                });

                                                if (validFiles.length > 0) {
                                                  const currentFiles = values.partners[selectedPartnerIndex]?.passport_files || [];
                                                  const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last two files
                                                  setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                  // setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, [
                                                  //   ...(values.partners[selectedPartnerIndex]?.passport_files || []),
                                                  //   ...validFiles
                                                  // ]);
                                                } else {
                                                  toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                }
                                              }}
                                              htmlFor={`partners.${selectedPartnerIndex}.passport_file`}
                                              className="h-[70%] border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg py-8"
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                id={`partners.${selectedPartnerIndex}.passport_file`}
                                                name={`partners.${selectedPartnerIndex}.passport_file`}
                                                multiple
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                onChange={(event) => {
                                                  const files = Array.from(event.currentTarget.files); // Convert FileList to an array
                                                  if (files.length === 0) return;

                                                  // Filter valid files by type and size
                                                  const validFiles = files.filter(file => {
                                                    const isValidFileType = VALID_IMAGE_TYPES.includes(file.type) || VALID_DOCUMENT_TYPES.includes(file.type);
                                                    const isValidFileSize = file.size <= MAX_FILE_SIZE; // Assuming MAX_FILE_SIZE is defined (e.g., 5 * 1024 * 1024 for 5MB)
                                                    return isValidFileType && isValidFileSize;
                                                  });

                                                  if (files.length > 2) {
                                                    toast.error("You can only select up to 2 files.");
                                                    event.target.value = ''; // Reset input field if more than 2 files selected
                                                    return;
                                                  }

                                                  if (validFiles.length > 0) {
                                                    // Get the current passport files and update the field with the new valid files, keeping only the last two
                                                    const currentFiles = values.partners[selectedPartnerIndex]?.passport_files || [];
                                                    const updatedFiles = [...currentFiles, ...validFiles].slice(-2); // Keep only the last 2 files

                                                    setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                  } else {
                                                    toast.error("Some files are invalid. Only PNG, JPG, JPEG, or PDF are allowed and must be less than 5 MB.");
                                                  }
                                                }}
                                              />

                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    values.partners?.[selectedPartnerIndex]?.passport_file
                                                      ? values.partners[selectedPartnerIndex].passport_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[1]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[1]?.file_name
                                                        : "Click to Upload Passport"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>

                                            {values.partners?.[selectedPartnerIndex]?.passport_files?.length > 0 ? (
                                              <div className="flex flex-col justify-center items-center gap-2 mt-2">
                                                {values.partners[selectedPartnerIndex]?.passport_files.map((file, index) => (
                                                  <div key={index} className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                    <p className="text-sm font-thin line-clamp-1">
                                                      {file.name}
                                                    </p>
                                                    <ClearIcon
                                                      className="cursor-pointer text-[#1E1D1B]"
                                                      sx={{ fontSize: "20px" }}
                                                      onClick={() => {
                                                        const updatedFiles = values.partners[selectedPartnerIndex].passport_files.filter((_, i) => i !== index);
                                                        setFieldValue(`partners[${selectedPartnerIndex}].passport_files`, updatedFiles);
                                                      }}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                                ?.filter(category => category.category_id === 2)
                                                ?.map((category) => (
                                                  category?.documents?.length > 0 && (
                                                    category.documents.map((doc, docIndex) => (
                                                      <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                        <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                          previous image: {doc.file_name}
                                                        </a>
                                                      </div>
                                                    ))
                                                  )
                                                ))
                                            )}
                                          </div>

                                          <div className="w-full lg:w-1/3">
                                            <p className="font-semibold"> Passport Photo<span className="text-red-500">*</span></p>
                                            <label
                                              onDragOver={(event) => event.preventDefault()}
                                              onDrop={(event) => {
                                                event.preventDefault();
                                                const file = event.dataTransfer.files[0];
                                                setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, file);
                                              }}
                                              htmlFor={`partners.${selectedPartnerIndex}.photo_file`}
                                              className="h-[70%] py-8 border-dashed cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg "
                                            >
                                              <VisuallyHiddenInput
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf"
                                                id={`partners.${selectedPartnerIndex}.photo_file`}
                                                name={`partners.${selectedPartnerIndex}.photo_file`}
                                                onChange={(event) => {
                                                  const file = event.currentTarget.files[0];
                                                  setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, file);
                                                }}
                                              />
                                              <div className="cursor-pointer flex justify-center flex-col items-center gap-3">
                                                <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M20 25H2C1.73478 25 1.48043 24.8946 1.29289 24.7071C1.10536 24.5196 1 24.2652 1 24V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H14L21 8V24C21 24.2652 20.8946 24.5196 20.7071 24.7071C20.5196 24.8946 20.2652 25 20 25Z" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M14 1V8H21" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M8 16H14" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                  <path d="M11 13V19" stroke="#1E1D1B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <Tooltip
                                                  title={
                                                    values.partners?.[selectedPartnerIndex]?.photo_file
                                                      ? values.partners[selectedPartnerIndex].photo_file?.name
                                                      : partners[selectedPartnerIndex]?.documents[2]?.file_name
                                                        ? partners[selectedPartnerIndex].documents[2]?.file_name
                                                        : "Click to Upload Photo"
                                                  }
                                                  placement="top"
                                                >
                                                  <p className="font-normal text-center text-xs text-[#1E1D1B]">
                                                    Drag and drop files here
                                                    <br /> or
                                                    Click to browse documents
                                                  </p>
                                                </Tooltip>
                                              </div>
                                            </label>
                                            {values.partners?.[selectedPartnerIndex]?.photo_file ? (
                                              <div className="flex flex-col justify-center items-center gap-4 mt-2">
                                                <div className="bg-[#EBEBE6] px-2 py-2 rounded-md font-xs w-full flex justify-between items-center">
                                                  <p className="text-sm font-thin line-clamp-1">
                                                    {values.partners[selectedPartnerIndex]?.photo_file?.name}
                                                  </p>
                                                  <ClearIcon
                                                    className="cursor-pointer text-[#1E1D1B]"
                                                    sx={{ fontSize: "20px" }}
                                                    onClick={() => {
                                                      setFieldValue(`partners[${selectedPartnerIndex}].photo_file`, null);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            ) : (
                                              partners[selectedPartnerIndex]?.documents
                                              ?.filter(category => category.category_id === 3)
                                              ?.map((category) => (
                                                category?.documents?.length > 0 && (
                                                  category.documents.map((doc, docIndex) => (
                                                    <div className="bg-[#EBEBE6] mt-2 px-2 py-2 rounded-md font-xs w-full flex justify-between items-center" key={`${category.category_id}-${docIndex}`}>
                                                      <a href={doc.url} target="_blank" rel="noopener noreferrer" className="text-sm font-thin line-clamp-1">
                                                        previous image: {doc.file_name}
                                                      </a>
                                                    </div>
                                                  ))
                                                )
                                              ))
                                            )}
                                          </div>
                                        

                                        </div>
                                        <Divider className="w-full pt-16
                                         text-[#8C8C8C] text-xs">

                                        </Divider>
                                        <div className="flex mt-3 justify-between gap-6 items-start">

                                          <div className="w-1/3 text-xs font-normal text-[#8C8C8C]">
                                            <p className="font-bold text-xs">All Documents Must Be:</p>
                                            <ul className="ml-3">
                                              <li className="list-outside list-disc">Clear full colour scans <br/> (no document photographs)</li>
                                              {/* <li className="list-outside list-disc">No document photographs</li> */}
                                              <li className="list-outside list-disc">Max file size 5mb</li>
                                            </ul>
                                          </div>
                                          <div className="w-1/3 text-xs font-normal text-[#8C8C8C]">

                                            <p className="font-bold text-xs">Passport Requirements:</p>
                                            <ul className="ml-3">
                                              <li className="list-outside list-disc">Photo and details pages</li>
                                              <li className="list-outside list-disc">Please also upload the address page <br/>if you have one</li>
                                            </ul>
                                          </div>
                                          <div className=" w-1/3 text-xs font-normal text-[#8C8C8C]">

                                            <p className="font-bold text-xs">Passport Photo Requirements:</p>
                                            <ul className="ml-3">
                                              <li className="list-outside list-disc">White photo background</li>
                                              <li className="list-outside list-disc">Same requirements as passport photo</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>

                              </DialogContent>
                            </Box>

                            <div>

                              <div className="flex justify-center">
                                {values.partners.length > 0 &&
                                  values.partners.map((partner, index) => (
                                    <>
                                      {/* <p 
                                        className={`px-3 cursor-pointer mt-2 mr-1 text-center py-1 text-sm font-medium ${index === selectedPartnerIndex ? 'bg-[#81EF6F]' : 'bg-[#EBEBE6]'
                                          } rounded-lg`}>{index + 1}</p> */}
                                      <div key={index}>
                                        <Brightness1Icon className="cursor-pointer" onClick={() => handlePartnerChange(index)} sx={{ fontSize: "13px", color: index === selectedPartnerIndex ? '#81EF6F' : '#EBEBE6', mr: 1 }} />
                                      </div>
                                    </>
                                  ))}
                              </div>
                              <Box
                                sx={{
                                  alignItems: "flex-end",
                                  display: "flex",
                                  gap: "0.1rem",
                                  mt: 1,
                                  width: "100%"
                                }}
                              >


                                <Button
                                  variant="contained"
                                  fullWidth
                                  sx={{
                                    backgroundColor: "rgb(212 212 216)",
                                    fontWeight: 600,
                                    "&:hover": {
                                      backgroundColor: "#81EF6F",
                                    },
                                    color: "#1E1D1B",
                                    borderRadius: "0px",
                                    textTransform: "none",
                                    minWidth: "30%",
                                  }}
                                  onClick={() => { setOpen(false) }}
                                >
                                  Save & Exit
                                </Button>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  sx={{
                                    backgroundColor: "#81ef6f",
                                    fontWeight: 600,
                                    color: "#1E1D1B",
                                    borderRadius: "0px",
                                    textTransform: "none",

                                    "&:hover": {
                                      backgroundColor: "#81EF6F",
                                    },
                                    display: "flex",

                                    gap: "10px",

                                  }}
                                  onClick={() => {
                                    if (selectedPartnerIndex < partners.length - 1) {
                                      // Increment the partner index if it's not the last partner
                                      setSelectedPartnerIndex(selectedPartnerIndex + 1);
                                    } else {
                                      // Close the modal if it's the last partner
                                      setOpen(false);
                                    }
                                  }}
                                // onClick={() => { setSelectedPartnerIndex(partners.length - 1 > selectedPartnerIndex ? selectedPartnerIndex + 1 : selectedPartnerIndex) }}
                                >
                                  Continue
                                </Button>
                              </Box>
                            </div >
                          </div>

                        </Modal>
                      </div>

                    </Box>
                  </>
                ))}

            </div>
            <div>
              <div className="pl-10 mt-2 mb-3 w-full">
                <hr className="text-[#EBEBE6] border w-[90%] mb-3" />
                <div onClick={() => setOpen(true)} className=" flex cursor-pointer justify-center gap-3 w-[90%] border-[#81EF6F] bg-[#E6FCE2] rounded-md items-center px-5 border-2 py-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9978 5.87895 15.1542 3.84542 13.6544 2.34562C12.1546 0.845814 10.121 0.00223986 8 0ZM8 14.7692C6.66118 14.7692 5.35241 14.3722 4.23922 13.6284C3.12603 12.8846 2.2584 11.8274 1.74605 10.5905C1.2337 9.35356 1.09965 7.99249 1.36084 6.67939C1.62203 5.36629 2.26674 4.16012 3.21343 3.21343C4.16013 2.26674 5.36629 1.62203 6.67939 1.36084C7.99249 1.09965 9.35356 1.2337 10.5905 1.74605C11.8274 2.25839 12.8846 3.12602 13.6284 4.23922C14.3722 5.35241 14.7692 6.66117 14.7692 8C14.7672 9.79469 14.0534 11.5153 12.7843 12.7843C11.5153 14.0534 9.79469 14.7672 8 14.7692ZM11.6923 8C11.6923 8.16321 11.6275 8.31973 11.5121 8.43514C11.3967 8.55055 11.2401 8.61538 11.0769 8.61538H8.61539V11.0769C8.61539 11.2401 8.55055 11.3967 8.43515 11.5121C8.31974 11.6275 8.16321 11.6923 8 11.6923C7.83679 11.6923 7.68027 11.6275 7.56486 11.5121C7.44945 11.3967 7.38462 11.2401 7.38462 11.0769V8.61538H4.92308C4.75987 8.61538 4.60334 8.55055 4.48794 8.43514C4.37253 8.31973 4.3077 8.16321 4.3077 8C4.3077 7.83679 4.37253 7.68026 4.48794 7.56486C4.60334 7.44945 4.75987 7.38461 4.92308 7.38461H7.38462V4.92308C7.38462 4.75987 7.44945 4.60334 7.56486 4.48793C7.68027 4.37253 7.83679 4.30769 8 4.30769C8.16321 4.30769 8.31974 4.37253 8.43515 4.48793C8.55055 4.60334 8.61539 4.75987 8.61539 4.92308V7.38461H11.0769C11.2401 7.38461 11.3967 7.44945 11.5121 7.56486C11.6275 7.68026 11.6923 7.83679 11.6923 8Z" fill="black" />
                  </svg>

                  <p >Click to Upload Documents</p>
                </div>
                {idCardError && (
                  <div className="text-red-500 mt-2 text-xs">
                    {idCardError}
                  </div>
                )}
              </div>


              <Box
                sx={{
                  alignItems: "flex-end",
                  display: "flex",
                  gap: "0.1rem",
                  borderTop: "2px solid #EBEBE6"
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={onBack}
                  disabled={isSubmitting}
                >
                  Previous
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "rgb(212 212 216)",
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    boxShadow: "none",
                    textTransform: "none",
                    maxWidth: "28%",
                  }}
                  onClick={async () => {
                    const errors = await validateForm();
                    if (Object.keys(errors).length === 0) {
                      handleSubmit(values, true);
                    } else {
                      submitForm();
                    }
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "#81ef6f",
                    fontWeight: 600,
                    color: "#1E1D1B",
                    borderRadius: "0px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#81EF6F",
                      boxShadow: "none",
                    },
                    display: "flex",
                    gap: "10px",
                    boxShadow: "none",
                  }}
                  disabled={isSubmitting}
                >
                  Next Step
                  {nextLoading && (
                    <svg
                      className="animate-spin w-6 h-6"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                    </svg>
                  )}
                </Button>
              </Box>
            </div>
          </Form >
        )}
      </Formik>
    </>
  );
};

export default RequiredDocument;
