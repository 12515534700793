import React, { useState, useEffect } from 'react';
import { Button, Box, Dialog, DialogActions, CircularProgress, DialogContent, Divider } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import success from "../../assets/success.jpg";
import { apiCallWithAxiosCancel, SaveFinalPayment } from "../../helpers/api";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetApplicationData, UpdateStripePaymentStatus, CreateStripePayment } from '../../helpers/api';
import LoadingModal from '../../helpers/LoadingModal';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import { set } from 'date-fns';



function FinalPayment({ onNext, onBack, onSaveAndExit, setMaxStepReached }) {
    const applicationId = useSelector((state) => state.app.applicationID);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [apiload, setApiload] = useState(false);
    const [open, setOpen] = useState(false); // State to manage modal open state
    const applicationID = useSelector((state) => state.app.applicationID);
    const [loading, setLoading] = useState(false);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState(null);
    const [costingBreakDown, setCostingBreakDown] = useState();
    const [totalPrice, setTotalPrice] = useState(0);
    const [lockStripe, setLockStripe] = useState(false);
    const [licenseCostBreakdown, setLicenseCostBreakdown] = useState();
    const [showingPaymentProcess, setShowingPaymentProcess] = useState(false);
    const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

    const handleCompleteClick = () => {
        setOpen(true); // Open the modal when the "Complete" button is clicked
    };

    const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

    useEffect(() => {
      const updateHeight = () => {
        setScreenHeight(window.innerHeight); // Update state when window is resized
      };
  
      // Add event listener for window resize
      window.addEventListener('resize', updateHeight);
  
      // Cleanup event listener on component unmount
      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

    useEffect(() => {
        async function fetchApplicationData() {
            try {
                setLoading(true);
                console.log("Fetching application data for ID:", applicationID);
                const ApplicationData = await GetApplicationData(applicationID, dispatch);
                setSelectedJurisdiction(ApplicationData?.data?.[0]?.jurisdiction?.[0]?.nameEn);
                setCostingBreakDown(ApplicationData?.data?.[0]?.cost_breakdown);
                setLicenseCostBreakdown(ApplicationData?.data?.[0]?.license_cost_breakdown);
                setTotalPrice(ApplicationData?.data?.[0]?.costing?.[3]?.up_front_cost);
                setMaxStepReached(ApplicationData?.data?.[0]?.step_no + 1);
                setLockStripe(ApplicationData?.data?.[0]?.step_no);
            } catch (error) {
                toast.error("Error occurred while fetching Application Data please refresh the page");
                console.error("Error occurred while fetching Application Data:", error);
            } finally {
                setLoading(false);
            }
        }

        if (applicationID) {
            fetchApplicationData();
        } else {
            console.error("No application ID found.");
        }
        return () => {
            setLoading(false); // Reset to ensure it doesn't persist when navigating back
        };
    }, [applicationID, dispatch]);



    function formatKey(key) {
        return key
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const [loadingStripeApi, setLoadingStripeApi] = useState(false)

    const handlePayment = async () => {
        setLoadingStripeApi(true)
        try {
            const payload = {
                amount: parseInt(totalPrice) * 100,
                currency: "aed",
                app_id: applicationID
            }
            setShowingPaymentProcess(true);
            console.log("Creating Stripe payment with payload:", payload);
            const response = await apiCallWithAxiosCancel((cancelToken) =>
                CreateStripePayment(payload, dispatch, cancelToken)
              );
            // const stripeUrl = `https://checkout.stripe.com/c/pay/${response.data.session_id}`;

            if (response.status === 200) {

                const stripe = await loadStripe(STRIPE_PUBLIC_KEY);
                if (stripe) {
                    console.log("Redirecting to Stripe checkout");
                    const { error } = await stripe.redirectToCheckout({
                        sessionId: response.data.session_id,
                    });
                    if (error) {
                        toast.error("Error redirecting to Stripe checkout");
                        setLoading(false);
                        // Handle error, e.g., show a message to the user
                    }
                    setLoading(false)
                }
                setLoading(false)
            }
            else {

                //if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
                toast.error("Error creating Stripe payment! try again later");
                setLoading(false);
                setShowingPaymentProcess(false);
                setLoadingStripeApi(false)
                //}

            }
            setLoading(false);
            setShowingPaymentProcess(false);
            // // Use window.location.href to directly navigate to the Stripe checkout page
            // window.location.href = stripeUrl;
            setLoadingStripeApi(false)
            console.log("Stripe payment created:", response);

        } catch (error) {
            if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
                console.error("Error creating Stripe payment:", error);
                setLoading(false);
                toast.error("Error creating Stripe payment");
                setLoading(false);
                setShowingPaymentProcess(false);
                setLoadingStripeApi(false)
            }
        }
        finally {
            setLoading(false);
            setShowingPaymentProcess(false);
            setLoadingStripeApi(false)
        }
    };

    console.log(selectedJurisdiction);
    console.log(costingBreakDown);
    const getTotalCostingBreakdown = () => {
        return costingBreakDown?.reduce((sum, item) => sum + (parseFloat(item?.price) || 0), 0);
    };
    const remainingAmount = totalPrice - getTotalCostingBreakdown();
    return (
        <> <LoadingModal open={loading} />
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ minHeight: "100%" }}
                flexDirection={"column"}
            >
                <Box sx={{ px: 5 }} marginTop={2}>
                    <p className="font-bold text-2xl  text-[#1E1D1B]">
                        Final Payment:
                    </p>
                    <p className="font-bold text-xl mt-2 text-[#1E1D1B]">
                        Selected Jurisdiction
                    </p>
                    <p className='mt-4 text-[#1E1D1B] font-semibold'>{selectedJurisdiction}</p>
                    <div style={{maxHeight: `calc(${screenHeight}px - 90px - 20px - 32px - 32px - 32px - 32px - 21px - 52px - 40px - 20px - 60px)`, overflowY:"auto"}} className='px-4 flex flex-col scrollbar overflow-y-auto pr-3 gap-3 mt-4'>
                        {
                            costingBreakDown && costingBreakDown.map((item, index) => {
                                return (
                                    <div key={index} className='flex gap-2'>
                                        <p className='w-1/2 text-[#1E1D1B] text-sm '>{formatKey(item.name)}</p>
                                        <p className='text-[#1E1D1B] text-sm font-bold'>{item.price}</p>
                                    </div>
                                );
                            })

                        }
                        {
                            licenseCostBreakdown && licenseCostBreakdown.map((item, index) => {
                                return (
                                    <div key={index} className='flex gap-2'>
                                        <p className='w-1/2 text-[#1E1D1B] text-sm '>{formatKey(item.name)}</p>
                                        <p className='text-[#1E1D1B] text-sm font-bold'>{item.price}</p>
                                    </div>
                                );
                            })
                        }
                        {/* <div className='flex gap-2'>
                            <p className='w-1/2 text-[#1E1D1B] text-sm '>Activity Cost</p>
                            <p className='text-[#1E1D1B] text-sm font-bold'>{remainingAmount}</p>
                        </div> */}
                    </div>



                </Box>
                <Box sx={{ px: 5 }}>
                    <Divider className="w-full text-[#EBEBE6] border-4 text-lg pt-5" />
                    <div className='flex justify-center'>
                        {
                            lockStripe > 6 ? <button type='button' onClick={() => navigate("/")} className='flex  font-semibold gap-2 mt-4 border-2 flex items-center py-1 border-[#1E1D1B] px-3 rounded-md'>
                                Payment is already made. Click to return to the Dashboard.
                            </button>
                                :
                                <button disabled={loadingStripeApi} type='button' onClick={handlePayment} className='flex  font-semibold gap-2 mt-4 border-2 flex items-center py-1 border-[#1E1D1B] px-3 rounded-md'>
                                    {showingPaymentProcess && (
                                        <svg
                                            className="animate-spin w-6 h-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                                        </svg>
                                    )}  Click to Pay Total <span className='font-semibold text-xs pl-4'>{totalPrice}</span>
                                </button>
                        }

                    </div>
                </Box>
                <Box
                    sx={{
                        alignItems: "flex-end",
                        display: "flex",
                        gap: "0.1rem",
                        mt: 3,
                        border: "2px solid #EBEBE6"
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "rgb(212 212 216)",
                            fontWeight: 600,
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "#81EF6F",
                                boxShadow: "none"
                            },
                            color: "#1E1D1B",
                            borderRadius: "0px",
                            textTransform: "none",
                            maxWidth: "50%"
                        }}
                        onClick={onBack}

                    >
                        Previous
                    </Button>

                    <Button
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: "#81ef6f",
                            fontWeight: 600,
                            color: "#1E1D1B",
                            borderRadius: "0px",
                            textTransform: "none",
                            boxShadow: "none",
                            "&:hover": {
                                backgroundColor: "#81EF6F",
                                boxShadow: "none",
                            },
                        }}
                        onClick={() => navigate("/")} // Open modal on button click

                    >
                        Save & Exit
                    </Button>
                </Box>
            </Box>

        </>
    );
}

export default FinalPayment;
