import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Container, Grid } from "@mui/material";
import NavbarNew from "./NavbarNew";
import * as Yup from 'yup';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const ResetPassword = () => {
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
const navigate = useNavigate();
  // Log the id to the console to verify it's being retrieved correctly
  useEffect(() => {
    console.log('Verification code (id) from URL:', id);
  }, [id]);

  const validationSchema = Yup.object({
    password: Yup.string().trim()
      .required('Password is required')
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
        "Password must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character"
      ),
    confirmPassword: Yup.string().trim()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  // Inside handleSubmit function
// Inside handleSubmit function
const handleSubmit = async (values, { setSubmitting, setStatus }) => {
  console.log('Form values:', values); // Log the form values
  try {
    const payload = {
      verification_code: id,
      new_password: values.password,
      confirm_password: values.confirmPassword,
    };
    console.log('Payload to be sent:', payload); // Log the payload

    // Use axios with the base URL
    const response = await axios.put(`https://g2scsqxnpk.ap-southeast-1.awsapprunner.com/api/auth/reset-password/`, payload);
    console.log('Password reset successful:', response);
    setStatus({ success: 'Password reset successful.' });
    navigate('/')
  } catch (error) {
    console.error('Password reset failed:', error);
    setStatus({ error: 'Password reset failed. Please try again.' });
  } finally {
    setSubmitting(false);
  }
};

  return (
    <div className="min-h-screen bg-[#EBEBE6]">
      <NavbarNew />
      <div className="flex items-center min-h-[80vh]">
        <Container>
          <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={12} sm={11} md={9} lg={6} xl={6}>
              <div className="bg-[#fbfafb] py-12 rounded-[20px] flex flex-col gap-8 items-center">
                <p className="text-[28px] text-[#1E1D1B] font-bold">Forget Your Password?</p>
                <Formik
                  initialValues={{ password: "", confirmPassword: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ status, isSubmitting }) => (
                    <Form className="space-y-4 flex flex-col items-center">
                      <div className="flex w-full flex-col items-start mb-10">
                        <label htmlFor="password" className="text-[#323338] text-[16px] font-semibold mb-1">Create Password</label>
                        <div className="relative w-80">
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            className="p-2 w-full bg-transparent rounded-md border-2 border-[#EBEBE6]"
                            placeholder="Create Password"
                            aria-labelledby="password"
                          />
                          <div
                            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </div>
                        </div>
                        <ErrorMessage name="password">
                          {msg => <div className="text-red-500 text-sm max-w-80">{msg}</div>}
                        </ErrorMessage>
                        <label htmlFor="confirmPassword" className="text-[#323338] text-[16px] font-semibold mt-4">Confirm Password</label>
                        <div className="relative w-80">
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            id="confirmPassword"
                            className="p-2 w-full bg-transparent rounded-md border-2 border-[#EBEBE6]"
                            placeholder="Confirm Password"
                            aria-labelledby="confirmPassword"
                          />
                          <div
                            className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </div>
                        </div>
                        <ErrorMessage name="confirmPassword">
                          {msg => <div className="text-red-500 text-sm">{msg}</div>}
                        </ErrorMessage>
                        <p className="mt-4 text-[#323338] text-[16px]">Example of a secure password:</p>
                        <p className="text-[#323338] text-[16px]">P@ssw0rd!2024</p>
                      </div>
                      {status?.error && <div className="text-red-500 text-sm">{status.error}</div>}
                      {status?.success && <div className="text-green-500 text-sm">{status.success}</div>}
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        fullWidth
                        sx={{
                          textTransform: "none",
                          backgroundColor: "#81EF6F",
                          borderRadius: "30px",
                          fontSize: "18px",
                          color: "#000000",
                          "&:hover": { backgroundColor: "#81EF6F" },
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting...' : 'Confirm'}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;






// import React, { useEffect } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Button, Container, Grid } from "@mui/material";
// import NavbarNew from "./NavbarNew";
// import * as Yup from 'yup';
// import { ResetPasswordActivity } from "../helpers/api"; // Import the function from api.js
// import { useParams } from "react-router-dom";

// const ResetPassword = () => {
//   const { id } = useParams();

//   // Log the id to the console to verify it's being retrieved correctly
//   useEffect(() => {
//     console.log('Verification code (id) from URL:', id);
//   }, [id]);

//   const validationSchema = Yup.object({
//     password: Yup.string().trim()
//       .required('Password is required')
//       .min(8, 'Password must be at least 8 characters'),
//     confirmPassword: Yup.string().trim()
//       .oneOf([Yup.ref('password'), null], 'Passwords must match')
//       .required('Confirm Password is required'),
//   });

//   const handleSubmit = async (values, { setSubmitting, setStatus }) => {
//     console.log('Form values:', values); // Log the form values
//     try {
//       const payload = {
//         verification_code: id,
//         new_password: values.password,
//         confirm_password: values.confirmPassword,
//       };
//       console.log('Payload to be sent:', payload); // Log the payload
//       const response = await ResetPasswordActivity(payload);
//       console.log('Password reset successful:', response);
//       setStatus({ success: 'Password reset successful.' });
//     } catch (error) {
//       console.error('Password reset failed:', error);
//       setStatus({ error: 'Password reset failed. Please try again.' });
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-[#EBEBE6]">
//       <NavbarNew />
//       <div className="flex items-center min-h-[80vh]">
//         <Container>
//           <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
//             <Grid item xs={12} sm={11} md={9} lg={6} xl={6}>
//               <div className="bg-[#fbfafb] py-12 rounded-[20px] flex flex-col gap-8 items-center">
//                 <p className="text-[28px] text-[#1E1D1B] font-bold">Forget Your Password?</p>
//                 <Formik
//                   initialValues={{ password: "", confirmPassword: "" }}
//                   validationSchema={validationSchema}
//                   onSubmit={handleSubmit}
//                 >
//                   {({ status, isSubmitting }) => (
//                     <Form className="space-y-4 flex flex-col items-center">
//                       <div className="flex w-full flex-col items-start mb-10">
//                         <label htmlFor="password" className="text-[#323338] text-[16px] font-semibold mb-1">Create Password</label>
//                         <Field
//                           type="password"
//                           name="password"
//                           id="password"
//                           className="p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6]"
//                           placeholder="Create Password"
//                           aria-labelledby="password"
//                         />
//                         <ErrorMessage name="password">
//                           {msg => <div className="text-red-500 text-sm">{msg}</div>}
//                         </ErrorMessage>
//                         <label htmlFor="confirmPassword" className="text-[#323338] text-[16px] font-semibold mt-4">Confirm Password</label>
//                         <Field
//                           type="password"
//                           name="confirmPassword"
//                           id="confirmPassword"
//                           className="p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6]"
//                           placeholder="Confirm Password"
//                           aria-labelledby="confirmPassword"
//                         />
//                         <ErrorMessage name="confirmPassword">
//                           {msg => <div className="text-red-500 text-sm">{msg}</div>}
//                         </ErrorMessage>
//                       </div>
//                       {status?.error && <div className="text-red-500 text-sm">{status.error}</div>}
//                       {status?.success && <div className="text-green-500 text-sm">{status.success}</div>}
//                       <Button
//                         type="submit"
//                         variant="contained"
//                         disableElevation
//                         fullWidth
//                         sx={{
//                           textTransform: "none",
//                           backgroundColor: "#81EF6F",
//                           borderRadius: "30px",
//                           fontSize: "18px",
//                           color: "#000000",
//                           "&:hover": { backgroundColor: "#81EF6F" },
//                         }}
//                         disabled={isSubmitting}
//                       >
//                         {isSubmitting ? 'Submitting...' : 'Confirm'}
//                       </Button>
//                     </Form>
//                   )}
//                 </Formik>
//               </div>
//             </Grid>
//           </Grid>
//         </Container>
//       </div>
//     </div>
//   );
// };

// export default ResetPassword;
