import * as Yup from "yup";

export const LoginvalidationSchema = Yup.object().shape({
  // username: Yup.string().trim()
  //   .email("Invalid email address")
  //   .required("Email is required"),
  username: Yup.string().trim()
  .email("Invalid email address")  // Yup's built-in email validation
  .matches(
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for more strict email validation
    "Invalid email address"
  )
  .required("Email is required"),

  password: Yup.string().trim()
    .required("Password is required")
    .min(7, "Must be 8 or more characters"),
});

export const RegistrationValidationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required("Name is required"),

  email: Yup.string().trim()
    .email("Invalid email address")  // Yup's built-in email validation
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for stricter validation
      "Invalid email address"
    )
    .required("Email is required"),

  password: Yup.string().trim()
    .required("Password is required")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
      "Password must contain at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character"
    ),
  
  phone_number: Yup.string()
    .min(9, "Phone number must be at least 9 digits")
    .max(16, "Phone number must be at most 15 digits")
    .required("Phone number is required")
});

