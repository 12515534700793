import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { apiCallWithAxiosCancel, GetApplicationData, GetLegalType, SaveLegalType } from "../../helpers/api";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingModal from "../../helpers/LoadingModal";

function LegalTypePage({ onNext, onBack, onSaveAndExit, setMaxStepReached }) {
  const applicationID = useSelector((state) => state.app.applicationID);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [LegalTypes, setLegalTypes] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedlegalTypeId, setselectedlegalTypeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [error, setError] = useState(""); // New state for error message
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);

  useEffect(() => {
    const fetchLegalTypes = async () => {
      try {
        setLoading(true);
        const response = await GetLegalType(applicationID, dispatch);
        if (response && response.data) {
          setLegalTypes(
            response.data.sort((a, b) => a.availability - b.availability)
          );
        } else {
          setError("Unexpected response structure");
        }
      } catch (error) {
        setError("Error occurred while fetching Legal Types Data");
      } finally {
        setLoading(false);
      }
    };

    fetchLegalTypes();
  }, [applicationID, dispatch]);

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === 'next') {
      console.log("next clicked")
      onNext();
    } else {
      navigate("/");
    }
  };

  const handleClick_legaltype = (id) => {
    setSelectedRow(id === selectedRow ? null : id);
    setselectedlegalTypeId(id === selectedRow ? "" : id.toString());
    setError(""); // Clear error on selecting a legal type
  };

  const handleNextClick = async (exit) => {
    if (!selectedlegalTypeId) {
      setError("Please select a legal type before proceeding.");
      return; // Prevent moving to the next step
    }

    const payload = {
      legal_type_id: selectedlegalTypeId,
      id: applicationID,
    };
    try {
      setNextLoading(true);
    // Use the cancellation logic for saving legal types
    const response = await apiCallWithAxiosCancel((cancelToken) =>
      SaveLegalType(payload, dispatch, cancelToken)
    );
      if (exit === true) {
        navigate("/");
      } else {
        onNext();
      }
    } catch (error) {
      if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
          setError("Error saving Legal Type Data");
      }
    } finally {
      setNextLoading(false);
    }
  };

  useEffect(() => {
    async function fetchApplicationData() {
      try {
        setLoading(true);
        const ApplicationData = await GetApplicationData(applicationID, dispatch);
        setMaxStepReached(ApplicationData?.data[0]?.step_no + 1);
        setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);
        if (ApplicationData.data.length > 0 && ApplicationData.data[0].legal_data !== null) {
          setselectedlegalTypeId(ApplicationData.data[0].legal_data[0].id);
          setSelectedRow(ApplicationData.data[0].legal_data[0].id);
        }
      } catch (error) {
        setError("Error occurred while fetching Application Data");
      } finally {
        setLoading(false);
      }
    }

    fetchApplicationData();
  }, [applicationID, dispatch, setMaxStepReached]);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  return (
    <>
      <LoadingModal open={loading} />
      <div className="flex flex-col min-h-[100%] w-full justify-between">
        <Box sx={{ px: 5 }} marginTop={2}>
          <p className="font-bold text-2xl text-[#1E1D1B]">Select Legal Type</p>
          <br />
          <div  className="relative sm:rounded-lg">
            <div style={{ height:"fit-content", maxHeight: `calc(${screenHeight}px - 90px - 32px - 32px - 32px - 40px - 50px)`}} className="relative w-full pr-5 scrollbar overflow-y-auto">
              {/* Gray overlay for the disabled state */}
              {isEverythingDisabled && (
                <div className="absolute inset-0 bg-gray-50 opacity-5 w-[90%] rounded-xl z-10 pointer-events-none" />
              )}

              <div
                className={`relative z-0 ${isEverythingDisabled ? 'opacity-50 pointer-events-none' : ''}`}
              >
                {LegalTypes &&
                  LegalTypes.map((type) => (
                    <div
                      key={type.id}
                      className={`flex items-center max-w-[530px] border-2 rounded-lg relative p-4 pr-10 py-3 mb-3 
                      ${selectedRow === type.id ? "bg-[#E6FCE2]" : "bg-[#FBFAFB]"} 
                      ${selectedRow === type.id ? "border-[#81EF6F]" : "border-gray-200"} 
                      ${type.availability === 1 || type.availability === 2 ? "opacity-50" : "cursor-pointer"}`}
                      onClick={() => !isEverythingDisabled && handleClick_legaltype(type.id)}
                      style={{
                        pointerEvents: isEverythingDisabled || type.availability === 1 || type.availability === 2 ? "none" : "auto",
                      }}
                    >
                      <div className="ml-4 flex w-full justify-between items-center">
                        <div className="font-medium text-lg">{type.nameEn}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

          </div>
        </Box>

        {/* Display error message */}
        {error && (
          <div className="text-red-600 text-center mb-4">
            {error}
          </div>
        )}

        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            gap: "0.1rem",
            mt: 1,
            borderTop: "2px solid #EBEBE6",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            sx={{
              boxShadow: "none",
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              color: "#1E1D1B",
              borderRadius: "0px",
              textTransform: "none",
              maxWidth: "28%",
            }}
            onClick={onBack}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "#81EF6F",
              },
              color: "#1E1D1B",
              borderRadius: "0px",
              textTransform: "none",
              maxWidth: "28%",
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('exit') : handleNextClick(true)}
          >
            Save & Exit
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#81ef6f",
              fontWeight: 600,
              color: "#1E1D1B",
              borderRadius: "0px",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              display: "flex",
              gap: "10px",
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('next'): handleNextClick(false)}
          >
            Next Step
            {nextLoading && (
              <svg
                className="animate-spin w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
              </svg>
            )}
          </Button>
        </Box>
      </div>
    </>
  );
}

export default LegalTypePage;
