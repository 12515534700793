import { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserChatHistory, UserChatSend } from '../../helpers/api';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import LoadingModal from '../../helpers/LoadingModal';
import { MessageSeen } from '../../helpers/api';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
    overflowY: "scroll",
};

const chatmessageValidation = Yup.object().shape({
    text: Yup.string().trim()
        .required("Text message is required"),
});
const ChatModal = ({ open, onClose, appId }) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [apiloading, setApiloading] = useState(false);
    const { user } = useSelector((state) => state.app);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchChatHistory() {
            if (open) {
                try {
                    setLoading(true);
                    const response = await UserChatHistory(appId);
                    setChatHistory(response.data);
                } catch (error) {
                    console.error("Error fetching chat history:", error);
                }
                finally {
                    setLoading(false);
                }
            }
        }
        async function chatMessageSeen() {
            if (open) {
                try {
                    const payload = {
                        app_id: appId,
                        is_user: true
                    }
                    const response = await MessageSeen(payload);
                }
                catch (error) {
                    console.error("Error fetching chat history:", error);
                }
            }
        }
        chatMessageSeen();
        fetchChatHistory();
    }, [open, appId]);

    const initialValues = {
        text: ""
    };

    const handleSubmit = async (values, { resetForm }) => {
        try {
            setApiloading(true);
            const newMessage = {
                app_id: appId,
                content: values.text,
                recipient_id: user?.id,
                timestamp: new Date().toISOString(),
                is_user: 0,
                is_agent: 1,
                agent_message: true,
            };
            const response = await UserChatSend(newMessage);
            setChatHistory([...chatHistory, newMessage]);
            toast.success("Message has been successfully sent to the User.");
            resetForm();
        } catch (error) {
            console.error("Error logging in:", error);
            toast.error("Error occurred while sending message to the User.");
        } finally {
            setApiloading(false);
        }
    };
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return format(date, 'p, MMM d, yyyy');
    };

    console.log("chatHistory", chatHistory);
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="chat-modal-title"
            aria-describedby="chat-modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >

            <Box className="scrollbar" sx={style} style={{ position: "relative", height: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} >

                <div className='overflow-y-auto scrollbar max-h-[78%] pr-2 pt-1'>

                    <div>
                        <div className="flex justify-end">
                            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} /></div>
                        <p className='text-2xl font-semibold text-center mb-6'>Send a message to the Client</p>

                        {loading === true && chatHistory.length === 0 &&
                            <div className="flex justify-center">
                                <svg
                                    className='animate-spin w-16 h-16'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z' />
                                </svg>
                            </div>
                        }
                        {loading === false && chatHistory.length === 0 &&
                            <div className="flex justify-center items-center h-40">
                                <p className="text-lg font-semibold text-gray-700">No chat history found.</p>
                            </div>
                        }
                        {console.log(chatHistory)}
                        {chatHistory.map((message) => (
                            <div key={message?.id} className="flex items-start mb-7 gap-2.5">
                                <Avatar sx={{ bgcolor: message.agent_message === true ? "#81ef6f" : "#81c6ef" }}>
                                    {message?.user_message === true ? (message?.user_name?.charAt(0).toUpperCase()) : 'A'}
                                </Avatar>
                                <div className="flex w-full flex-col gap-1">
                                    <div className="flex flex-col min-w-full leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                                        <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
                                            <span className="text-sm font-semibold text-gray-900 dark:text-white">
                                                {message.agent_message == true ? "Agent" : message?.user_name}
                                            </span>
                                            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{formatDate(message?.timestamp)}</span>
                                        </div>
                                        <p className="text-sm font-normal text-gray-900 dark:text-white">{message?.content}</p>
                                        {
                                            message?.documents && message?.documents[0] && (
                                                <div className="group relative my-2.5">
                                                    <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                                                        <button data-tooltip-target="download-image" className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50">
                                                            <a href={message?.documents[0]?.document_url} download={message?.documents[0]?.document_name}>
                                                                <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3" />
                                                                </svg>
                                                            </a>
                                                        </button>
                                                        <div id="download-image" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                                            Download image
                                                            <div className="tooltip-arrow" data-popper-arrow></div>
                                                        </div>
                                                    </div>
                                                    <img src={message?.documents[0]?.document_url} className="rounded-md object-cover object-center" alt='images sent by the user' />
                                                </div>
                                            )
                                        }

                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                            {message?.sender === "agent" ? "sent" : "received"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                <div>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={chatmessageValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form
                                fullWidth
                                className="space-y-3 flex flex-col items-center"
                            >
                                <div className="flex mt-3 w-full flex-col items-start">
                                    <div className="text-[#323338] font-semibold mb-1">
                                        Text Message
                                    </div>
                                    <Field
                                        as={TextField}
                                        name="text"
                                        multiline
                                        rows={2}
                                        placeholder="Write the information that you want to get from the Client."
                                        fullWidth
                                        variant="outlined"
                                        error={touched.text && !!errors.text}
                                        helperText={touched.text && errors.text}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#81EF6F",
                                        fontWeight: 600,
                                        "&:hover": {
                                            backgroundColor: "#81EF6F",
                                        },
                                        color: "#1E1D1B",
                                        borderRadius: "0px",
                                        textTransform: "none",
                                        maxWidth: "28%",
                                    }}
                                    endIcon={
                                        apiloading && <svg
                                            className='animate-spin w-6 h-6'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'
                                        >
                                            <path d='M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z' />
                                        </svg>
                                    }
                                    type="submit"
                                >
                                    Send Message
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Box>
        </Modal>
    );
};

export default ChatModal;
