import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { CreateAgent } from "../helpers/api";
import { Button, Typography, Container, Divider, Grid, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const LoginvalidationSchema = Yup.object().shape({
    username: Yup.string().trim()
    .email("Invalid email address")  // Yup's built-in email validation
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,  // Additional regex for stricter validation
      "Invalid email address"
    )
    .required("Email is required"),
    password: Yup.string().trim()
        .required("Password is required")
        .min(7, "Must be 8 or more characters"),
    first_name: Yup.string().trim().required("Name is required"),
});

function CreateAgentForm() {
    const [apiloading, setApiloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = {
        username: "",
        password: "",
        first_name: ""
    };
    const validationSchema = LoginvalidationSchema;
    const handleSubmit = async (values) => {
        const payload = {
            email: values.username,
            first_name: values.first_name,
            password: values.password,
            is_agent: true,
        }

        setApiloading(true);
        try {
            const response = await CreateAgent(payload, dispatch)
            console.log("user login info", response.data.user);
            setApiloading(false);
            toast.success("Agent Created Successfully");
            navigate("/admin")

        } catch (error) {
            console.error("Error logging in:", error);
            setApiloading(false);
            toast.error("Oops! Something went wrong.Please Try Again Later", {
                position: "bottom-right",
                toastId: "signin_fails",
            });
        }
    };

    return (
        <div>
            <Container>
                <Grid
                    container
                    sx={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Grid item xs={12} sm={11} md={9} lg={6} xl={6}>
                        <div className="bg-[#fbfafb] py-9 rounded-[20px] flex flex-col gap-8 items-center">
                            <p className="text-3xl text-[#1E1D1B] font-bold">Create Agent</p>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ errors, touched }) => (
                                    <Form
                                        fullWidth
                                        className="space-y-4 flex flex-col items-center"
                                    >
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold mb-1">
                                                Name
                                            </div>
                                            <Field
                                                type="text"
                                                fullWidth
                                                name="first_name"
                                                className={`p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6] ${errors.first_name && touched.first_name
                                                    ? "border-red-600"
                                                    : "border-[#EBEBE6]"
                                                    }`}
                                                placeholder="Enter Agent Full Name"
                                            />
                                            {errors.first_name && touched.first_name ? (
                                                <div className="text-red-500 text-sm">
                                                    {errors.first_name}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold mb-1">
                                                Email
                                            </div>
                                            <Field
                                                type="username"
                                                fullWidth
                                                name="username"
                                                className={`p-2 w-80 bg-transparent rounded-md border-2 border-[#EBEBE6] ${errors.username && touched.username
                                                    ? "border-red-600"
                                                    : "border-[#EBEBE6]"
                                                    }`}
                                                placeholder="Enter Agent Email"
                                            />
                                            {errors.username && touched.username ? (
                                                <div className="text-red-500 text-sm">
                                                    {errors.username}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="flex w-full flex-col items-start">
                                            <div className="text-[#323338] font-semibold mb-1">
                                                Password
                                            </div>
                                            <Field
                                                type="password"
                                                fullWidth
                                                name="password"
                                                className={`p-2 w-full  bg-transparent rounded-md border-2 border-[#EBEBE6] ${errors.password && touched.password
                                                    ? "border-red-600"
                                                    : "border-[#EBEBE6]"
                                                    }`}
                                                placeholder="Enter Password"
                                            />
                                            {errors.password && touched.password ? (
                                                <div className="text-red-500 text-sm">
                                                    {errors.password}
                                                </div>
                                            ) : null}
                                        </div>


                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                            fullWidth
                                            sx={{
                                                textTransform: "none",
                                                backgroundColor: "#81EF6F",
                                                borderRadius: "30px",
                                                fontSize: "18px",
                                                color: "#000000",
                                                "&:hover": {
                                                    backgroundColor: "#81EF6F",
                                                },
                                            }}
                                            endIcon={
                                                apiloading && <svg
                                                    className='animate-spin w-6 h-6'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    viewBox='0 0 512 512'
                                                >
                                                    <path d='M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z' />
                                                </svg>
                                            }
                                        >
                                            Create Agent
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Grid>
                </Grid>
            </Container>

        </div>
    );
}

export default CreateAgentForm;