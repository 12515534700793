import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  SvgIcon, Drawer
} from "@mui/material";
import { Logout } from "../authservices/Logout"
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import logo from "../assets/svgs/firmfox-logo.svg"
import { useNavigate, useLocation } from 'react-router-dom';
import { apiCallWithAxiosCancel } from '../helpers/api';


function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.app);
  const [apiloading, setApiloading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check if the drawer is open and screen size is greater than a certain breakpoint
      if (openDrawer && window.innerWidth > 1024) {
        setOpenDrawer(false); // Close the drawer
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [openDrawer]);

  const HandleLogout = async () => {
    try {
      setApiloading(true);
      await apiCallWithAxiosCancel((cancelToken) =>
        Logout(dispatch, {}, cancelToken)
    );      setApiloading(false);
    } catch (err) {
      if(err?.code !== "ERR_CANCELED" || err?.name !== "CanceledError"){
        console.log(err);
        setApiloading(false);
        // toast.error("Oops! Something went wrong.Please Try Again Later", {
        //   position: "bottom-right",
        //   toastId: "signin_fails",
        // });
      }

    }
  };
  console.log("role of the user", role)
  const location = useLocation();

  return (
    <div className='2xl:mx-[100px]'>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#ebebe6",
          alignItems: "center",
          minHeight: "90px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >

        <div className='flex justify-between px-10 w-full lg:justify-between items-center gap-3'>
          <Box sx={{ display: "flex", gap: "2.5rem", }}>
            <img src={logo} alt="logo of firmfox" />
          </Box>
          <div className="hidden xl:flex ">
            <div className=' flex justify-center'>
              {
                role &&
                <>
                  <div className='lg:w-44 '>
                    <Button
                      variant='text'
                      sx={{
                        color: "#1E1D1B",
                        fontSize: "17px",
                        textTransform: "none",
                      }}
                      // onClick={() => Logout(dispatch, user)}
                      onClick={() => navigate('/admin')}
                    >
                      Admin Dashboard
                    </Button>
                  </div>
                  <div className='lg:w-36 '>
                    <Button onClick={() => navigate("/create-agent")} sx={{
                      color: "#1E1D1B",
                      fontSize: "17px",
                      textTransform: "none",
                    }} className={` capitalize text-red-500 ${location.pathname === "/create-agent" ? 'underline' : ''} cursor-pointer min-w-36  text-start text-[17px] font-normal`}>Create Agent</Button>

                  </div></>

              }

            </div>
            <div className='lg:w-36 '>
              <Button
                onClick={() => navigate('/')}
                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
              >
                Dashboard
              </Button>
            </div>

            <div className='lg:w-44 '>
            
              <Button

                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
                onClick={() => navigate('/account-settings')}
              >
                Account Settings
              </Button>
              
            </div>
            <div className='lg:w-28 flex justify-center '>
              <Button
                variant='text'
                sx={{
                  color: "#1E1D1B",
                  fontSize: "17px",
                  textTransform: "none",
                }}
                endIcon={
                  apiloading && <svg
                    className="animate-spin w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                  </svg>
                }
                // onClick={() => Logout(dispatch, user)}
                onClick={HandleLogout}
              >
                Sign Out
              </Button>
            </div>

            <div className='w-36 lg:flex justify-end hidden '>
              {" "}
              <a href="https://firmfox.com/contact-us" rel="no" target="_blank">
              <Button
                variant='outlined'
                sx={{
                  fontSize: "17px",
                  textTransform: "none",
                  color: "#1E1D1B",
                  border: "2px solid #1E1D1B",
                  borderRadius: "30px",
                  "&:hover": {
                    backgroundColor: "#1E1D1B",
                    color: "white",
                    border: "2px solid #1E1D1B",
                  },
                }}
              >
                Get in Touch
              </Button>
              </a>
            </div>
          </div>
          <SvgIcon
            onClick={() => setOpenDrawer(true)}
            sx={{
              cursor: "pointer",
              display: { xs: "block", lg: "none" },
            }}
          >
            <svg
              fill="grey"
              style={{ cursor: "pointer" }}
              className="w-44"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
            </svg>{" "}
          </SvgIcon>

        </div>
      </Box>
      <Drawer
        sx={{ xs: "block", lg: "none" }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        role="presentation"
      >
        <Box sx={{ width: "50vw", padding: "3.5%", background: "white" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              pt: 1,
              marginLeft: "4%",
            }}
          >

          </Box>
          <div className='flex flex-col justify-center gap-2 pt-10 '>
            <img src={logo} width="57%" alt="logo of firmfox" onClick={() => setOpenDrawer(false)} className='mb-20 w-8/12' />
            <p onClick={() => navigate("/")} className="cursor-pointer text-start text-2xl font-bold">Dashboard</p>
            <p onClick={() => navigate("/foxchat")} className="text-start text-2xl font-bold cursor-pointer ">Fox Chat</p>
            <a href="https://firmfox.com/our-services" rel="no" target="_blank">
            <p className="text-start text-2xl font-bold cursor-pointer ">Our Services</p>
            </a>
            <p onClick={HandleLogout} className="text-start text-2xl font-bold cursor-pointer mb-8">Sign Out</p>

            <Button variant="contained" sx={{
              fontSize: '18px', textTransform: "none", color: "white", backgroundColor: "#1E1D1B", '&:hover': {
                backgroundColor: '#1E1D1B'
              }
            }} >Find Out More</Button>

          </div>
        </Box>
      </Drawer>
    </div>
  );
}

export default Navbar;