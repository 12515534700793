import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import {
  Button,
  Box,
  Divider,
  CircularProgress,
  Typography,
  DialogContent,
  Tooltip,
  TextField,
  InputAdornment,
  Modal,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  apiCallWithAxiosCancel,
  GetApplicationData,
  GetMainActivities,
  GetSubActivities,
  SaveBusinessActivities,
  SearchSubBusinessActivities,
} from "../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";
import "../../../src/index.css";
import { useDispatch } from "react-redux";
import { setApplicationID } from "../../features/appSlice";
import LoadingModal from "../../helpers/LoadingModal";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { FixedSizeList } from 'react-window';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //width: {sm: "100%", md: "70%", lg: "50%"},
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function BusinnessActivities({
  onNext,
  onBack,
  onSaveAndExit,
  setMaxStepReached,
}) {
  const applicationID = useSelector((state) => state.app.applicationID);
  const [subActivitiesData, setSubActivitiesData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mainActivity, setMainActivity] = useState("");
  const [mainActivityNameEn, setMainActivityNameEn] = useState([]);
  const [apiloading, setApiloading] = useState(false);
  const [mainActivityId, setMainActivityId] = useState([]);
  const [mainActivitiyDialogue, setMainActivitiyDialogue] = useState(false);
  const [subActivity, setSubActivity] = useState([]);
  const [subActivityId, setSubActivityId] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [subActivitiyDialogue, setSubActivitiyDialogue] = useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [nextLoading, setNextLoading] = useState(false);
  const [saveExit, setSaveExit] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(true);
  const [mainactivitiesBigId, setMainactivitiesBigId] = useState([]);
  const [mainactivityBigName, setMainactivityBigName] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageSize, setPageSize] = useState(1);
  const [isEverythingDisabled, setIsEverythingDisabled] = useState(false);
  const [extraValue, setExtraValue] = useState([]);
  const observer = useRef();
  const [allSubActivitiesData, setAllSubActivitiesData] = useState([]);
  const [filteredSubActivitiesData, setFilteredSubActivitiesData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [business_error, setBusinessError] = useState("")

  const [appComplete, setAppComplete] = useState(false)


  const debouncedSearch = useMemo(
    () =>
      debounce(async (searchText) => {
        if (searchText) {
          setIsLoadingMore(true);
          setIsSearching(true);
          //  setSubActivitiesData([]); // Reset previous results when searching
          try {
            // Call the search API with the search text
            const response = await SearchSubBusinessActivities(searchText, dispatch);
            if (response && response.data) {
              setFilteredSubActivitiesData(response.data); // Set the new search results
            } else {
              console.error("Invalid response from SearchSubBusinessActivities API");
            }
          } catch (error) {
            console.error("Error occurred during search:", error);
          } finally {
            setIsLoadingMore(false);
          }
        }
        else {
          setIsSearching(false);
          setFilteredSubActivitiesData([]); // Clear filtered data when search text is empty
        }
      }, 750),
    []
  );

  // Trigger search when searchText changes
  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);

  const divRef = useRef(null); // Ref to the div element
  // const [divHeight, setDivHeight] = useState(0); // State to store the div's height

  // useEffect(() => {
  //   // Delayed ResizeObserver setup to give time for the layout to stabilize
  //   const observerTimeout = setTimeout(() => {
  //     const handleResize = (entries) => {
  //       for (let entry of entries) {
  //         // Update height when the div's dimensions change
  //         setDivHeight(entry.contentRect.height);
  //       }
  //     };

  //     const resizeObserver = new ResizeObserver(handleResize);

  //     if (divRef.current) {
  //       resizeObserver.observe(divRef.current); // Observe the div's resizing
  //     }

  //     // Cleanup observer on component unmount
  //     return () => {
  //       if (divRef.current) {
  //         resizeObserver.unobserve(divRef.current);
  //       }
  //     };
  //   }, 100); // Delay of 100ms

  //   return () => {
  //     clearTimeout(observerTimeout); // Clear the timeout on unmount
  //   };
  // }, []);

  // console.log("div height component = ", divHeight)

  // useEffect(()=>{
  //   console.log("div height useEffect = ", divHeight)
  // } , [divHeight])

  const [screenHeight, setScreenHeight] = useState(window.innerHeight); // Initial height

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight); // Update state when window is resized
    };

    // Add event listener for window resize
    window.addEventListener('resize', updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const handleIsEverythingDisabled = (strategy) => {
    if (strategy === 'next') {
      onNext();
    } else {
      navigate("/");
    }
  };




  useEffect(() => {
    async function fetchMainActivies() {
      try {
        setNextLoading(true)

        const MainActivities = await GetMainActivities(dispatch);
        setMainActivity(MainActivities.data);
      } catch (error) {
        toast.error("Error occured while fetching Main Activities Data");
      } finally {
        setNextLoading(false);
      }
    }
    fetchMainActivies();
  }, []);
  const getSubActivities = async (page) => {
    if (page > 96) {
      return;
    }
    setNextLoading(true)
    setIsLoadingMore(true);
    try {
      const response = await GetSubActivities(page,
        dispatch
      );
      if (response && response.data) {
        setSubActivitiesData((prevData) => [...prevData, ...response.data?.results]);

      } else {
        console.error("Invalid response from GetSubActivities API");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNextLoading(false)
      setIsLoadingMore(false);
    }
  };
  useEffect(() => {
    getSubActivities(currentPage);
  }, [currentPage]);

  const lastActivityElementRef = useCallback(node => {
    if (isLoadingMore) return;
    if(searchText !== "") return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        if (currentPage < 97) {
          setCurrentPage(prevPage => prevPage + 1);
        }
        else {
          return;
        }

      }
    });
    if (node) observer.current.observe(node);
  }, [currentPage, isLoadingMore,searchText]);

  useEffect(() => {
    if (applicationID === null) return;
    async function fetchApplicationData() {
      try {
        setNextLoading(true);
        const ApplicationData = await GetApplicationData(
          applicationID,
          dispatch
        );

        const applicationCompletionStatus = ApplicationData?.data[0]?.is_completed ? ApplicationData?.data[0]?.is_completed : false 
        console.log("app complete = ", applicationCompletionStatus)
        setAppComplete(applicationCompletionStatus)

        setMaxStepReached(ApplicationData?.data[0]?.step_no + 1);
        setIsEverythingDisabled(ApplicationData?.data[0]?.is_completed);
        if (
          ApplicationData.data.length > 0 &&
          ApplicationData.data[0].sub_activities !== null
        ) {
          console.log(ApplicationData.data);

          const mainActivityName =
            ApplicationData.data[0]?.main_business_activities?.map(
              (item) => item.nameEN
            );

          const mainActivityId =
            ApplicationData.data[0]?.main_business_activities?.map(
              (item) => item.id
            );

          setMainactivitiesBigId(mainActivityId || []);
          setMainactivityBigName(mainActivityName || []);

          const subActivityIds = ApplicationData?.data[0]?.sub_activities.map(
            (item) => item.id
          );
          console.log("asdfasdasds", ApplicationData.data[0].sub_activities);

          setSubActivityId(subActivityIds);

          const subActivityNameEns = ApplicationData.data[0].sub_activities.map(
            (item) => item.activityNameEn
          );
          setExtraValue([...ApplicationData?.data[0]?.sub_activities])
          setSelectedActivities(subActivityNameEns);

          console.log("change in subActivityNameEns are :", subActivityNameEns);
          console.log("change in subactivities ids :", subActivityIds);
          console.log("change in the main activities name: ", mainActivityName);
        }
      } catch (error) {
        toast.error("Error occurred while fetching Application Data");
      } finally {
        setNextLoading(false);
      }
    }
    fetchApplicationData();
  }, []);

  useEffect(() => {
    console.log("subActivitiesData i am here:", subActivitiesData);
    if (subActivitiesData?.length > 0) {
      // Group sub-activities by main activity ID
      console.log("i am here");
      const groupedSubActivities = subActivitiesData?.reduce(
        (acc, subActivity) => {
          const mainActivityId = subActivity?.business_main_activities_id;
          if (!acc[mainActivityId]) {
            acc[mainActivityId] = [];
          }
          acc[mainActivityId].push(subActivity);
          return acc;
        },
        {}
      );
      setSubActivity(groupedSubActivities);
    }
  }, [subActivitiesData]);


  const CloseSubActivityDialouge = () => {
    setFilterEnabled(false);
    setSearchText("");
    setSubActivitiyDialogue(false);
  };

  const handleOpenMainDialoge = () => {
    try {
      setNextLoading(true);
      //setSubActivityId([]); // Reset subActivityId when changing main activity
      //setSelectedActivities([]);
      if (subActivitiesData) {
        setSubActivitiyDialogue(true);
        // setFilterEnabled(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Oops!Sever Error ", {
        position: "bottom-right",
        toastId: "get business activities",
      });
    }
    finally {
      setNextLoading(false);
    }
    //
  };
  const handleActivityClick = (activity) => {
    const { id, activityNameEn, business_main_activities_id } = activity;
    if (selectedActivities.includes(activityNameEn)) {
      handlesubactivityid(id);
      SubActivityClick(activityNameEn);
      return; // Exit here, so no maximum selection check is needed
    }

    if (selectedActivities.length > 6) {
      toast.error("You can select maximum of 7 activities", { toastId: "max activities" });
      return;
    }
    handlesubactivityid(id);
    SubActivityClick(activityNameEn);
    setExtraValue([...extraValue, activity]);

    if (!mainactivitiesBigId.includes(business_main_activities_id)) {
      const mainBusinessName = mainActivity.find(
        (item) => item.id === business_main_activities_id
      )?.nameEN;
      console.log("acha chalta hooo", mainBusinessName);
      setMainactivitiesBigId((prev) => [...prev, business_main_activities_id]);
      setMainactivityBigName((prev) => [...prev, mainBusinessName]);
    }
  };


  const handlesubactivityid = (id) => {
    console.log(id);
    if (subActivityId.includes(id)) {
      setSubActivityId(subActivityId.filter((subId) => subId !== id));
    } else {
      setSubActivityId((prev) => [...prev, id]);
    }
  };

  const SubActivityClick = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities((prev) =>
        prev.filter((selectedActivity) => selectedActivity !== activity)
      );
    } else {
      setSelectedActivities((prev) => [...prev, activity]);
    }
  };

  const RemoveSubActivity = (activity) => {
    // Find the activity object based on the name
    const activityObj = extraValue.find(
      (subActivity) => subActivity.activityNameEn === activity
    );

    console.log("the activity object is ", activityObj);

    // Remove the activity name from selectedActivities
    setSelectedActivities((prev) =>
      prev.filter((selectedActivity) => selectedActivity !== activity)
    );

    // Remove the corresponding activity ID from subActivityId
    if (activityObj) {
      setSubActivityId((prev) =>
        prev.filter((id) => id !== activityObj.id)
      );
    }
  };



  const ActivitesSelection = async (exit) => {
    if (!subActivityId.length > 0) {
      // toast.error("Please Select Activity first", {
      //   toastId: "Check Activities",
      // });
      setBusinessError("Please Select Activity first")
    } else {
      setBusinessError("")
      try {
        setApiloading(true);
        let ActivitiesResults;
        console.log("main activity sending toi the api", mainactivitiesBigId);
        const payload = {
          main_activity_ids: mainactivitiesBigId !== null ? mainActivityId : [],
          sub_activity_ids: subActivityId,
        };
        console.log("the payload is ", payload);

        if (applicationID !== null) {
          payload.id = applicationID;
          ActivitiesResults = await apiCallWithAxiosCancel((cancelToken) =>
            SaveBusinessActivities(payload, dispatch, cancelToken)
          );
          // sessionStorage.setItem("ss", "sss")

        } else {
          ActivitiesResults = await apiCallWithAxiosCancel((cancelToken) =>
            SaveBusinessActivities(payload, dispatch, cancelToken)
          );          
          dispatch(setApplicationID(ActivitiesResults.data.id));
        }

        console.log("============> ActivitiesResults", ActivitiesResults)

        setSubActivity(ActivitiesResults.data);

        console.log("the save exit switch is ", saveExit);
        if (exit === true) {
          navigate("/");
        } else {
          onNext();
        }
      } catch (error) {
        if(error?.code !== "ERR_CANCELED" || error?.name !== "CanceledError"){
          //return;        
          toast.error("Error Saving Activity Details.", {
          toastId: "save subactivities",
          });
        }
      } finally {
        setApiloading(false);
        setSaveExit(false);
      }
    }
  };
  console.log();


  console.log("activities of sub");
  console.log("selected activities");
  const allSubActivities = useMemo(() => {
    return Object?.values(subActivity)?.flat();
  }, [subActivity]);

  const filteredSubActivities = useMemo(() => {
    const uniqueSubActivities = new Map();

    allSubActivities
      ?.filter((activity) =>
        activity.activityNameEn.toLowerCase().includes(searchText.toLowerCase())
      )
      .sort((a, b) => {
        const nameA = a.activityNameEn.toLowerCase();
        const nameB = b.activityNameEn.toLowerCase();
        const searchLower = searchText.toLowerCase();

        const indexA = nameA.indexOf(searchLower);
        const indexB = nameB.indexOf(searchLower);

        if (indexA === 0 && indexB === 0) return 0;
        if (indexA === 0) return -1;
        if (indexB === 0) return 1;

        return indexA - indexB;
      })
      .forEach((activity) => {
        uniqueSubActivities.set(activity.activityNameEn, activity);
      });

    return Array.from(uniqueSubActivities.values());
  }, [allSubActivities, searchText]);





  const itemSize = 80;
  const displayedSubActivities = isSearching ? filteredSubActivitiesData : subActivitiesData;


  //console.log("filtered data", filteredSubActivities);
  console.log("sub activity data", subActivitiesData);
  console.log("main activity name from api", mainActivityNameEn);
  console.log("main activity id from api", mainActivityId);
  console.log("main big activiteis", mainactivitiesBigId);
  console.log("main big activiteis name", mainactivityBigName);

  return (
    <>
            {/* <ToastContainer/> */}

      <LoadingModal open={nextLoading || isLoadingMore} />
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        className="min-h-[100%]"
        sx={{background:"white"}}
      >
        <Box sx={{ px: 5 }} marginTop={2}>
          <p className="font-bold text-2xl text-[#1E1D1B]">
            What type of business would you like to set-up/register?
          </p>
          <br />

          <Button
            fullWidth
            variant="outlined"
            onClick={handleOpenMainDialoge}
            sx={{
              textTransform: "none",
              color: "#8C8C8C",
              fontSize: "16px",
              justifyContent: "flex-start",
              padding: "7px",
              borderRadius: "6px",
              border: "2px solid #EBEBE6",
              "&:hover": {
                border: "2px solid #81ef6f",
              },
              width: "430px",
              maxWidth:"100%"
            }}
            disabled={isEverythingDisabled}
            startIcon={
              <SearchIcon className={`mr-2 ml-3  text-[#8C8C8C]`} size={28} />
            }
          >
            Search Activity Related To Your Business
          </Button>

          {
            business_error?.length ? <div className="error-msg-div"><p className="error-msg-text">{business_error}</p></div> : ""
          }
          
          <Divider
            sx={{
              marginTop: "3.2rem",
              marginBottom: "0.5rem",
            }}
          />

          {mainActivity !== "" &&
            subActivityId.length > 0 &&
            subActivitiyDialogue === false && (
              <>
                <p className="font-bold mt-3 text-xl text-[#1E1D1B]">
                  {/* outter selected activities */}
                  Your selected activities:
                </p>

                <div style={{ height:"fit-content", maxHeight: `calc(${screenHeight}px - 90px - 20px - 32px - 32px - 32px - 46px - 30px - 32px - 60px - 40px)`, overflowY:"auto"}} className="scrollbar overflow-y-auto">
                <div  className="flex flex-wrap mt-3.5">
                  {selectedActivities.map((activity, index) => (
                    <Tooltip placement="top" title={activity}>
                      <div
                        key={index}
                        className="wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[20px] text-white text-sm flex items-center justify-between mr-2 mb-2 cursor-pointer"

                      >
                        <span className="wrapped-capsule">{activity}</span>
                        <button
                          className="p-1"
                          onClick={(e) => {
                            e.preventDefault();
                            if(appComplete === false){
                              RemoveSubActivity(activity);
                            }
                          }}                        >
                          <MdClose hidden={appComplete} className="w-4 h-4 text-white" />
                        </button>
                      </div>                      
                    </Tooltip>

                  ))}
                </div>
              </div>
              </>
            )}
        </Box>

        <Modal
          open={subActivitiyDialogue}
          onClose={CloseSubActivityDialouge}
          sx={{ px: 1 }}
        >
          <div
            ref={divRef}
            style={{
              position: "absolute",
              top: "90px",
              left: "50%",
              transform: "translate(-50%, -15px)",
              height: "88vh",
            }}
            className="min-w-[90%] flex flex-col scrollbar justify-between  overflow-y-auto  rounded-[20px] bg-white shadow-md "
          >
            <Box className="md:px-8 md:pt-6 p-3">
              <MdClose
                onClick={CloseSubActivityDialouge}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "15",
                  right: "15",
                }}
                className="w-6 h-6 text-[#000000]"
              />

              <DialogContent sx={{ padding: "0px", mt: 0 }}>
                <div className="flex mb-0 gap-10 items-start">
                  <TextField
                    className="search-bar"
                    variant="outlined"
                    placeholder="Search Sub Activities"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      style: {
                        maxHeight: "40px",
                        borderRadius: "6px",
                      },
                    }}
                    sx={{
                      marginBottom: "0.5rem",
                      width: "440px",
                      borderRadius: "10px",
                    }}
                  />
                </div>
                <div >
                  <div className="grid grid-cols-2 w-[85%]">
                    <p className="pl-5">Activities</p>
                    <p >Description</p>
                  </div>
                  <div className="flex flex-col mt-1 mb-3  cursor-pointer">
                    {displayedSubActivities.length > 0 ? (

                      <FixedSizeList
                        height={((screenHeight - (screenHeight * 0.12) - 90 - 65 - 32 - 150 - 37) < 350 ? 350 : (screenHeight - (screenHeight * 0.12) - 90 - 65 - 32 - 150 - 37) )}
                        width="100%"
                        itemSize={85}
                        itemCount={displayedSubActivities.length}
                        className="scrollbar flex flex-col"
                      >
                        {({ index, style }) => {
                          return (
                            <div style={style} ref={index === displayedSubActivities.length - 1 && displayedSubActivities.length > 97 ? lastActivityElementRef : null}
                              key={displayedSubActivities[index]}>
                              <div

                                className={`grid grid-cols-2 gap-2 w-[85%] text-[#1E1D1B] items-start mt-0 mr-2 px-5 py-3 rounded-[15px] text-[0.8em] ${selectedActivities.includes(displayedSubActivities[index].activityNameEn)
                                  ? "bg-[#E6FCE2] border-2 border-[#81EF6F] text-[#1E1D1B]"
                                  : "bg-[#EDEDE9]"
                                  }`} onClick={() => handleActivityClick(displayedSubActivities[index])}>
                                <div className="flex flex-col justify-between">
                                  <Tooltip title={displayedSubActivities[index].activityNameEn}>
                                    <p className="font-medium text-base line-clamp-1">{displayedSubActivities[index].activityNameEn}</p>
                                  </Tooltip>
                                  <div style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "250px",
                                    position: "relative",
                                  }} className="grid grid-cols-3 items-center">
                                    <p className="pt-3 font-normal text-xs">{displayedSubActivities[index].licenseTypeEn} License</p>
                                    {/*<p className="pt-3 font-normal text-xs line-clamp-1">Allowed Jurisdicion: {displayedSubActivities[index].allowed_jurisdiction}</p>*/}
                                  </div>
                                </div>
                                <div className="my-auto">
                                  <Tooltip title={displayedSubActivities[index].description}>
                                    <p className="font-normal line-clamp-3 text-xs">{displayedSubActivities[index].description}</p>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </FixedSizeList>

                    ) : (
                      !isLoadingMore && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                          No activities found.
                        </Typography>
                      )
                    )}
                  </div>
                </div>
              </DialogContent>
              <Divider sx={{ marginTop: "0.5rem" }} />
            </Box>

            <Box
              px={5}
              sx={{
                height: "auto",
                minHeight: "12vh",
                paddingBottom: "10px",
              }}
            >
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  marginTop: "0px",
                  marginBottom: "2px",
                  fontWeight: 600,
                  fontSize: "22px",
                }}
              >
                Your selected activities:
              </Typography>
              <div className={`scrollbar overflow-y-auto ${(screenHeight - (screenHeight * 0.12) - 90 - 65 - 32 - 150 - 37) < 350 ? "max-h-[55px]" : "max-h-[102px]" }`}>
                <div className="flex flex-wrap mt-3.5 ">
                  {selectedActivities.map((activity, index) => (
                    <Tooltip placement="top" title={activity}>
                      <div
                        key={index}
                        className="cursor-pointer wrapped-capsule px-3 py-2 bg-[#1E1D1B] rounded-[10px] text-white text-sm flex items-center justify-between mr-2 mb-2"
                      >
                        <span className="wrapped-capsule">{activity}</span>
                        <button
                          className="p-1"
                          onClick={() => RemoveSubActivity(activity)}
                        >
                          <MdClose hidden={appComplete} className="w-4 h-4 text-white" />
                        </button>
                      </div>                      
                    </Tooltip>

                  ))}
                </div>
              </div>
            </Box>
            <Box
              sx={{
                alignItems: "flex-end",
                display: "flex",
                gap: "0.2rem",
                mt: 2,
              }}
            >
              <div className="w-1/2"></div>


              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "rgb(212 212 216)",
                  fontWeight: 600,
                  "&:hover": {
                    backgroundColor: "#81EF6F",
                  },
                  color: "#1E1D1B",
                  borderRadius: "0px",
                  textTransform: "none",
                  maxWidth: "25%",
                }}
                onClick={CloseSubActivityDialouge}
              >
                Exit Search
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#81ef6f",
                  fontWeight: 600,
                  color: "#1E1D1B",
                  borderRadius: "0px",
                  textTransform: "none",
                  maxWidth: "25%",
                  "&:hover": {
                    backgroundColor: "#81EF6F",
                  },
                  display: "flex",

                  gap: "10px",
                }}
                onClick={CloseSubActivityDialouge}
                endIcon={
                  apiloading && <CircularProgress size={20} color="inherit" />
                }
              >
                Continue
              </Button>
            </Box>
          </div>
        </Modal>
        <Box
          sx={{
            alignItems: "flex-end",
            display: "flex",
            gap: "0.1rem",
            mt: 2,
            width: "100%",
            borderTop: "2px solid #EBEBE6"
          }}
        >
          {
            applicationID === null && (<Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "rgb(212 212 216)",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#81EF6F",
                  boxShadow: "none",
                },
                color: "#000000",
                boxShadow: "none",
                borderRadius: "0px",
                textTransform: "none",
                maxWidth: "28%",
              }}
              onClick={() => navigate("/")}
            >
              Cancel & Exit
            </Button>
            )
          }
          
          <Button
            variant="contained"
            fullWidth
            disabled={apiloading}
            sx={{
              backgroundColor: "rgb(212 212 216)",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              color: "#000000",
              boxShadow: "none",
              borderRadius: "0px",
              textTransform: "none",
              maxWidth: applicationID === null ? "28": "40",
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('exit') : ActivitesSelection(true)}
          >
            Save & Exit
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={apiloading}
            sx={{
              backgroundColor: "#81ef6f",
              fontWeight: 600,
              color: "#000000",
              borderRadius: "0px",
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#81EF6F",
                boxShadow: "none",
              },
              minWidth: applicationID === null ? "48": "60%"
            }}
            onClick={() => isEverythingDisabled ? handleIsEverythingDisabled('next') : ActivitesSelection(false)}
            endIcon={
              apiloading && (
                <svg
                  className="animate-spin w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
                </svg>
              )
            }
          >
            Next Step
          </Button>
        </Box>
      </Box >
    </>
  );
}

export default BusinnessActivities;
