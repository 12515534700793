import { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from 'react-toastify';
import { UserChatHistory, UserChatSend, MessageSeen } from '../../helpers/api';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import LoadingModal from '../../helpers/LoadingModal';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
    overflowY: "scroll",
};

const chatmessageValidation = Yup.object().shape({
    text: Yup.string().trim().required("Text message is required"),
    file: Yup.mixed().nullable().notRequired()
});

const UserChatModal = ({ open, onClose, appId }) => {
    const location = useLocation();
    const [chatHistory, setChatHistory] = useState([]);
    const [apiloading, setApiloading] = useState(false);
    const { user } = useSelector((state) => state.app);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [hasReloaded, setHasReloaded] = useState(false);
    const handleChange = (event) => {
        setSelectedFile(event.currentTarget.files[0]);
    };

    const handleClose = () => {
        if (location.pathname === '/') {
            // Refresh the page when the modal closes and the path is '/'
            window.location.reload();
        } else {
            // Call the passed onClose function if provided
            if (onClose) {
                onClose();
            }
        }
    }

    useEffect(() => {
        setSelectedFile(null);
        async function fetchChatHistory() {
            if (open) {
                try {
                    setLoading(true);
                    const response = await UserChatHistory(appId);
                    setChatHistory(response.data);
                } catch (error) {
                    console.error("Error fetching chat history:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        async function chatMessageSeen() {
            if (open) {
                try {
                    const payload = {
                        app_id: appId,
                        is_agent: true
                    }
                    const response = await MessageSeen(payload);
                }
                catch (error) {
                    console.error("Error fetching chat history:", error);
                }
            }
        }
        chatMessageSeen();
        fetchChatHistory();

    }, [open, appId]);

    const initialValues = {
        text: "",
        file: null
    };

    const handleSubmit = async (values, { resetForm }) => {
        try {
            setApiloading(true);
            const formData = new FormData();
            formData.append('app_id', appId);
            formData.append('content', values.text);
            formData.append('recipient_id', user?.id);
            formData.append('timestamp', new Date().toISOString());
            formData.append('is_user', 1);
            formData.append('is_agent', 0);
            if (selectedFile) {
                formData.append('files', selectedFile);
            }

            const response = await UserChatSend(formData);
            const newMessage = {
                app_id: appId,
                content: values.text,
                recipient_id: user?.id,
                timestamp: new Date().toISOString(),
                sender: "user",
                documents: selectedFile ? [{
                    document_name: selectedFile.name,
                    size: selectedFile.size,
                    type: selectedFile.type
                }] : null
            };
            setChatHistory([...chatHistory, newMessage]);
            setSelectedFile(null);
            toast.success("Message has been successfully sent to the Agent.");
            resetForm();
        } catch (error) {
            console.error("Error sending message:", error);
            toast.error("Error occurred while sending message to the Client.");
        } finally {
            setApiloading(false);
        }
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return format(date, 'p, MMM d, yyyy');
    };

    const formatFileSize = (size) => {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="chat-modal-title"
            aria-describedby="chat-modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box className="scrollbar" sx={style} style={{position: "relative", height: "70%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                
                <div className='overflow-y-auto scrollbar max-h-[65%] pr-2 pt-1'>
                <div className="flex justify-end">
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} /></div>
                    <p className='text-2xl font-semibold text-center mb-6'>Client message history</p>
                    <div>
                        {loading === false && chatHistory.length === 0 &&
                            <div className="flex justify-center items-center h-40">
                                <p className="text-lg font-semibold text-gray-700">No chat history found</p>
                            </div>
                        }

                        {loading === true ?

                            <div className="flex justify-center">
                                <svg
                                    className='animate-spin w-16 h-16'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 512 512'
                                >
                                    <path d='M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z' />
                                </svg>
                            </div>
                            :
                            (
                                <>
                                    {chatHistory?.map((message) => (
                                        <div key={message?.id} className="flex items-start mb-7 gap-2.5">
                                            <Avatar sx={{ bgcolor: message.agent_message === true  ? "#81ef6f" : "#81c6ef" }}>
                                                {message?.sender?.charAt(0).toUpperCase()}
                                            </Avatar>
                                            <div className="flex w-full flex-col gap-1">
                                                <div className="flex flex-col min-w-full leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700">
                                                    <div className="flex items-center space-x-2 rtl:space-x-reverse mb-2">
                                                        <span className="text-sm font-semibold text-gray-900 dark:text-white">
                                                        {message?.agent_message === true ? "Agent" : user?.first_name}
                                                        </span>
                                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">{formatDate(message?.timestamp)}</span>
                                                    </div>
                                                    <p className="text-sm font-normal text-gray-900 dark:text-white">{message?.content}</p>
                                                    {message?.documents && message?.documents[0] && (
                                                        <div className="flex items-start my-2.5 bg-gray-50 dark:bg-gray-600 rounded-xl p-2">
                                                            <div className="me-2">
                                                                <span className="flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-white pb-2">
                                                                    <svg fill="none" aria-hidden="true" className="w-5 h-5 flex-shrink-0" viewBox="0 0 20 21">
                                                                        <g clipPath="url(#clip0_3173_1381)">
                                                                            <path fill="#E2E5E7" d="M5.024.5c-.688 0-1.25.563-1.25 1.25v17.5c0 .688.562 1.25 1.25 1.25h12.5c.687 0 1.25-.563 1.25-1.25V5.5l-5-5h-8.75z" />
                                                                            <path fill="#B0B7BD" d="M15.024 5.5h3.75l-5-5v3.75c0 .688.562 1.25 1.25 1.25z" />
                                                                            <path fill="#CAD1D8" d="M18.774 9.25l-3.75-3.75h3.75v3.75z" />
                                                                            <path fill="#F15642" d="M16.274 16.75a.627.627 0 01-.625.625H1.899a.627.627 0 01-.625-.625V10.5c0-.344.281-.625.625-.625h13.75c.344 0 .625.281.625.625v6.25z" />
                                                                            <path fill="#fff" d="M3.998 12.342c0-.165.13-.345.34-.345h1.154c.65 0 1.235.435 1.235 1.269 0 .79-.585 1.23-1.235 1.23H4.838c-.21 0-.34-.19-.34-.354v-1.8zm.766.33v1.19h.788c.31 0 .56-.2.56-.59 0-.39-.25-.6-.56-.6h-.788zM7.276 12c.21 0 .34.18.34.345v2.558c0 .19-.13.33-.34.33-.18 0-.34-.14-.34-.33v-2.558c0-.165.16-.345.34-.345zM9.168 14.64c.28 0 .465-.215.465-.53 0-.215-.155-.4-.38-.465l-.76-.225c-.23-.07-.36-.245-.36-.495 0-.33.26-.56.615-.56.19 0 .38.05.53.155.115.075.19.21.16.34 0 .155-.155.25-.305.25-.07 0-.14 0-.19-.04-.08-.035-.15-.07-.23-.07-.155 0-.275.12-.275.27 0 .15.08.225.195.26l.76.225c.18.055.32.165.4.32.08.155.115.27.115.43 0 .35-.28.615-.695.615-.185 0-.365-.045-.535-.135-.14-.09-.2-.215-.17-.375 0-.155.15-.26.305-.26.08 0 .16.02.24.055l.19.08h.015zM10.518 14.64c.28 0 .465-.215.465-.53 0-.215-.155-.4-.38-.465l-.76-.225c-.23-.07-.36-.245-.36-.495 0-.33.26-.56.615-.56.19 0 .38.05.53.155.115.075.19.21.16.34 0 .155-.155.25-.305.25-.07 0-.14 0-.19-.04-.08-.035-.15-.07-.23-.07-.155 0-.275.12-.275.27 0 .15.08.225.195.26l.76.225c.18.055.32.165.4.32.08.155.115.27.115.43 0 .35-.28.615-.695.615-.185 0-.365-.045-.535-.135-.14-.09-.2-.215-.17-.375 0-.155.15-.26.305-.26.08 0 .16.02.24.055l.19.08h.015zM12.492 14.765h1.086c.18 0 .34.18.34.345s-.16.33-.34.33h-1.846c-.21 0-.34-.14-.34-.33v-2.558c0-.165.13-.345.34-.345h1.846c.18 0 .34.18.34.345s-.16.345-.34.345h-1.086v.955h.71c.2 0 .34.14.34.33 0 .19-.14.33-.34.33h-.71v.883z" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_3173_1381">
                                                                                <rect width="20" height="20" fill="white" transform="translate(0 .5)" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    <p>Document</p>
                                                                </span>
                                                                <div className="flex flex-col gap-1">
                                                                    <span className="text-sm font-medium text-gray-900 dark:text-white">{message?.documents[0]?.document_name}</span>
                                                                    <span className="text-xs font-normal text-gray-500 dark:text-gray-400">{formatFileSize(message?.message?.documents[0]?.size)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )
                        }

                    </div>
                </div>

                <div className=''>
                  
                    <Formik
                        initialValues={initialValues}
                        validationSchema={chatmessageValidation}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, errors, touched }) => (
                            <Form className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Field
                                    name="text"
                                    as={TextField}
                                    fullWidth
                                    label="Text message"
                                    variant="outlined"
                                    multiline
                                    error={touched.text && Boolean(errors.text)}
                                    helperText={touched.text && errors.text}
                                    rows={2}
                                    sx={{ mb: 1 }}
                                />
                                <div className="w-full mb-2 items-center cursor-pointer justify-center border-2 flex gap-1 bg-[#e6fce2] border-[#81EF6F] px-3 rounded-lg py-4">
                                    <VisuallyHiddenInput
                                        type="file"
                                        id="photos-upload"
                                        name="file"
                                        onChange={handleChange}
                                    />
                                    <label htmlFor="photos-upload" className="cursor-pointer flex justify-center gap-2">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346628 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9978 5.87895 15.1542 3.84542 13.6544 2.34562C12.1546 0.845814 10.121 0.00223986 8 0ZM8 14.7692C6.66117 14.7692 5.35241 14.3722 4.23922 13.6284C3.12603 12.8846 2.2584 11.8274 1.74605 10.5905C1.2337 9.35356 1.09965 7.99249 1.36084 6.67939C1.62203 5.36629 2.26674 4.16012 3.21343 3.21343C4.16013 2.26674 5.36629 1.62203 6.67939 1.36084C7.99249 1.09965 9.35356 1.2337 10.5905 1.74605C11.8274 2.25839 12.8846 3.12602 13.6284 4.23922C14.3722 5.35241 14.7692 6.66117 14.7692 8C14.7673 9.80089 14.0447 11.5245 12.7695 12.7695C11.4944 14.0145 9.76953 14.7368 8 14.7692Z" fill="black" />
                                            <path d="M11.6923 8C11.6923 8.16321 11.6275 8.31974 11.5121 8.43514C11.3967 8.55055 11.2401 8.61538 11.0769 8.61538H8.61539V11.0769C8.61539 11.2401 8.55055 11.3967 8.43514 11.5121C8.31974 11.6275 8.16321 11.6923 8 11.6923C7.83679 11.6923 7.68027 11.6275 7.56486 11.5121C7.44945 11.3967 7.38462 11.2401 7.38462 11.0769V8.61538H4.92308C4.75987 8.61538 4.60334 8.55055 4.48794 8.43514C4.37253 8.31973 4.30769 8.16321 4.30769 8C4.30769 7.83679 4.37253 7.68026 4.48794 7.56486C4.60334 7.44945 4.75987 7.38461 4.92308 7.38461H7.38462V4.92308C7.38462 4.75987 7.44945 4.60334 7.56486 4.48793C7.68027 4.37253 7.83679 4.30769 8 4.30769C8.16321 4.30769 8.31974 4.37253 8.43514 4.48793C8.55055 4.60334 8.61539 4.75987 8.61539 4.92308V7.38461H11.0769C11.2401 7.38461 11.3967 7.44945 11.5121 7.56486C11.6275 7.68026 11.6923 7.83679 11.6923 8Z" fill="black" />
                                        </svg>
                                        <p className="font-normal text-sm text-[#1E1D1B]">
                                            {selectedFile ? selectedFile.name : "Click to Upload Document"}
                                        </p>
                                    </label>

                                </div>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: "#81EF6F",
                                        fontWeight: 600,
                                        "&:hover": {
                                            backgroundColor: "#81EF6F",
                                        },
                                        color: "#1E1D1B",
                                        borderRadius: "0px",
                                        textTransform: "none",
                                        maxWidth: "28%",
                                    }}

                                    type="submit" color="primary" disabled={apiloading}>
                                    {apiloading ? "Sending..." : "Send Message"}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
                {apiloading && <LoadingModal />}
            </Box>
        </Modal>
    );
};

export default UserChatModal;
